import { useEffect } from "react";
import { IImportSettingsSteps } from "../ImportSettingsStep1";
import { getDistinctValues } from "../../utils";
import { useImportSettings } from "../useImportSettings";

export function useAutoMapValues(props: IImportSettingsSteps) {
    const { ready, getListFromField } = useImportSettings();

    async function autoMapValues() {
        if (!ready) {
            return;
        }
        const { transformSchema, fullData, setTransformSchema } = props;
        const columns = transformSchema?.map || [];
        for (const column of columns) {
            if (column.dbColumnName && !column.ignore) {
                const list: string[] = getListFromField(
                    column.dbColumnName
                ).map((i: Compleo.IObject) => i["label-pt-BR"]);
                const distinctFileValues = getDistinctValues(
                    fullData,
                    column.id
                );
                for (const fileValue of distinctFileValues) {
                    const dbValue = list.find((item) => item === fileValue);
                    if (dbValue) {
                        column.mapValues = column.mapValues || [];
                        // first check if the value is already mapped
                        if (
                            column.mapValues.find(
                                (i) => i.fileValue === fileValue
                            )
                        ) {
                            continue;
                        }
                        column.mapValues.push({
                            fileValue,
                            dbValue: dbValue
                        });
                    }
                }
            }
        }
    }

    useEffect(() => {
        autoMapValues();
    }, [ready]);
}
