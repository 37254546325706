import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";

export interface DbField {
    id: string;
    label: string;
    arrayIndex?: number;
    fullDef: Compleo.IObject;
}
const arrayItemsTotal = 5;

const fieldsToIgnore = ["location"];

interface IUseImportSettings {
    returnOnlyBaseComplexFields?: boolean;
}

export function useImportSettings(params?: IUseImportSettings) {
    const { returnOnlyBaseComplexFields = false } = params || {};
    const moduleName = "APPLICANTNEW";
    const [t, , readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });

    const [metadata] = useGetMetadata(moduleName);

    const ready = readyTranslation && metadata.status === "success";
    const listas = metadata?.response?.data?.listas || {};
    let dbFields: DbField[] = [];
    if (ready) {
        const metadataFields = metadata?.response?.data?.metadado || [];
        const complexFieldsFullDefList = metadataFields.filter(
            (item: Compleo.IObject) => item.elementType === "complexField"
        );
        const complexFields = complexFieldsFullDefList.map(
            (item: Compleo.IObject) => {
                return item.fieldName;
            }
        );
        dbFields = metadataFields
            .filter((i: Compleo.IObject) => {
                return (
                    ![
                        "complexField",
                        "hidden",
                        "file",
                        "multipleFiles",
                        "image"
                    ].includes(i.elementType) &&
                    !fieldsToIgnore.includes(i.fieldName) &&
                    !i.elementType.includes("backend") &&
                    !complexFields.some((customField: string) =>
                        i.fieldName.includes(`${customField}_`)
                    )
                );
            })
            .map((field: any) => {
                const returnField: DbField = {
                    id: field.fieldName,
                    label: t(field.fieldName),
                    fullDef: field
                };
                return returnField;
            });

        for (const complexFieldDef of complexFieldsFullDefList) {
            const complexField = complexFieldDef.fieldName;
            const complexFieldItems = metadataFields.filter(
                // eslint-disable-next-line no-loop-func
                (item: Compleo.IObject) =>
                    item.fieldName.includes(`${complexField}_`)
            );
            const mainItemLabel = t(complexField);
            if (returnOnlyBaseComplexFields) {
                dbFields.push({
                    id: complexField,
                    label: mainItemLabel,
                    fullDef: complexFieldDef
                });
                continue;
            } else {
                for (let i = 0; i < arrayItemsTotal; i++) {
                    for (const complexFieldItem of complexFieldItems) {
                        const fieldLocalName = complexFieldItem.fieldName
                            .replace("COMPLEXFIELD:", "")
                            .replace("_", ".");
                        const labelLocalFieldName = `${mainItemLabel} - ${t(
                            fieldLocalName
                        )}`;

                        dbFields.push({
                            id: `${complexFieldItem.fieldName}-${i + 1}`,
                            label: labelLocalFieldName + ` ${i + 1}`,
                            arrayIndex: i + 1,
                            fullDef: complexFieldItem
                        });
                    }
                }
            }
        }
    }

    if (returnOnlyBaseComplexFields) {
        dbFields = dbFields.filter(
            (item: DbField) =>
                !item?.fullDef?.fieldName.includes("COMPLEXFIELD:")
        );
    }

    function getListFromField(field?: string) {
        let localField = field;
        if (!localField) {
            return [];
        }
        if (localField.includes("COMPLEXFIELD:")) {
            localField = localField.split("-")[0];
        }
        return listas[localField] || [];
    }

    return { ready, dbFields, getListFromField, t };
}
