import Page from "Pages/_Layouts/DefaultInternal";
import useCompleoReactHookForm from "customHooks/useCompleoReactHookForm";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import { useApi, useApiCache } from "customHooks/useApi";
import FormClassic from "customHooks/useCompleoReactHookForm/components/FormClassic";
import { useAuthState } from "_ReactContext/AuthContext";
import useValuesFromSource from "customHooks/useValuesFromSource";
import { useCancelButtonForm } from "customHooks/useCancelButtonForm";

const JobTitleEdit = (props: any) => {
    const cancelFunction = useCancelButtonForm("/jobtitle/list");

    const { company } = useAuthState();

    const [metadata] = useGetMetadata("JOBTITLEEDIT");
    const [t, i18n, readyTranslation] = useTranslation("JOBTITLEEDIT", {
        useSuspense: false
    });
    console.log("metadata", metadata);
    const postAddress = `/jobtitle/jobtitleedit`;

    const [editBranchReturn, editBranch] = useApi(postAddress, "post");

    const postAddressSearch = `/jobtitle/jobtitlesearch`;
    const [getBranchReturn] = useApiCache(
        postAddressSearch,
        "post",
        {
            id: props.uuid,
            companyId: company.companyId
        },
        false
    );

    const [valuesFromSource] = useValuesFromSource(
        metadata,
        true,
        {},
        getBranchReturn,
        ["data", "data"]
    );
    valuesFromSource.id = props.uuid;

    const [formCompleo, finished] = useCompleoReactHookForm({
        FormType: FormClassic,
        i18nV: i18n,
        metadadosRetorno: metadata,
        postMethod: editBranch,
        postReturn: editBranchReturn,
        ready: readyTranslation,
        valuesFromSource: valuesFromSource,
        t: t,
        secondaryButtonFunction: cancelFunction,
        redirectAddress: "/jobtitle/list"
    });

    let compRetorno: boolean | JSX.Element;
    compRetorno = <Loading />;

    if (finished && readyTranslation && getBranchReturn.status === "success") {
        compRetorno = formCompleo;
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
            >
                {compRetorno}
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

export default JobTitleEdit;
