/* eslint-disable no-loop-func */
import _ from "lodash";
import React from "react";
import { useEffect } from "react";
import { UseFormWatch } from "react-hook-form";

interface IUseJobTemplateDisableFields {
    metadata: any;
    watch: UseFormWatch<Compleo.IObject>;
}

export function useJobTemplateDisableFields(
    params: IUseJobTemplateDisableFields
) {
    const { metadata, watch } = params;
    const [metadataState, setMetadataState] = React.useState<any>(metadata);
    const fieldsToDisable: string[] = watch("fieldsToDisable");

    useEffect(() => {
        console.log("metadata", metadata);
        const localMetadata = _.cloneDeep(metadata);
        if (
            fieldsToDisable &&
            localMetadata?.response?.data?.metadado &&
            fieldsToDisable.length
        ) {
            for (const fieldToDisableName of fieldsToDisable) {
                console.log("fieldToDisableName", fieldToDisableName);
                const result = localMetadata.response.data.metadado.find(
                    (item: Compleo.IObject) =>
                        item.fieldName === fieldToDisableName
                );
                if (result) {
                    localMetadata.response.data.metadado.find(
                        (item: Compleo.IObject) =>
                            item.fieldName === fieldToDisableName
                    ).elementConfig = {
                        ...result.elementConfig,
                        disabled: true
                    };
                }
            }
        }
        setMetadataState(localMetadata);
    }, [JSON.stringify(fieldsToDisable), metadata.status]);
    return metadataState;
}
