import React from "react";
import Autocomplete, { AutocompleteProps } from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { getControlOptionsAndChildrenFilterV2 } from "./util/AutoCompleteUtil";
import _ from "lodash";
import { getErrorAndMessages } from "./util/general";
import {
    IInputProps,
    useRhfFieldControlled
} from "../helpers/reactHookFormsHelper";
import { useWatch } from "react-hook-form";

export interface ISelectProps {
    options: any;
    customLists?: Compleo.IObject[];
    multiple?: boolean;
    parentFilterField?: Compleo.IObject;
    childrenFilterFieldName?: string;
    language?: string;
    groupByField?: string;
    disableClearable?: boolean;
    campoMetadata: Compleo.IObject;
    row?: boolean;
    freeSolo?: boolean;
    setValueToDefaultIfNotInList?: boolean;
    isHtml?: boolean;
    disabled?: boolean;
}

const ComboAutoComplete = (props: ISelectProps & IInputProps) => {
    const {
        name,
        multiple,
        label,
        options,
        helperTextDefault,
        campoMetadata,
        customLists,
        required,
        groupByField,
        language: languageProps,
        setValueToDefaultIfNotInList = true,
        ...other
    } = props;
    const language = languageProps || "pt-BR";
    const {
        error,
        message,
        setFieldValue,
        field,
        getValues,
        watch,
        setValue,
        control
    } = useRhfFieldControlled(name, helperTextDefault);

    const multipleControl = multiple === undefined ? false : multiple;

    let defaultValue: any = null;
    if (multipleControl) {
        defaultValue = [];
    }

    let controlValue = defaultValue;
    const valueFromFormik = watch(field.name);

    const fieldValue = useWatch({
        control,
        name: props?.parentFilterField?.parentFieldName
    });
    const controlOptionsAndChildrenFilter = getControlOptionsAndChildrenFilterV2(
        campoMetadata,
        options,
        customLists,
        name,
        fieldValue,
        props
    );
    const controlOptions = controlOptionsAndChildrenFilter.controlOptions;
    const childrenFilterFieldName =
        controlOptionsAndChildrenFilter.childrenFilterFieldName;

    const childrenFieldValue = useWatch({
        control,
        name: childrenFilterFieldName
    });

    if (
        valueFromFormik !== "" &&
        valueFromFormik !== undefined &&
        valueFromFormik !== null
    ) {
        if (!valueFromFormik.label) {
            const newValue = controlOptions.filter(
                (item: any) => item.value === valueFromFormik.value
            );
            if (newValue.length > 0) {
                controlValue = newValue[0];
                if (controlValue.label === undefined) {
                    controlValue.label = newValue[0][`label-${language}`];
                }
            } else {
                controlValue = valueFromFormik;
            }
        } else {
            controlValue = valueFromFormik;
        }
    }

    React.useEffect(() => {
        if (
            valueFromFormik &&
            (controlOptions || []).length > 0 &&
            !(controlOptions || []).find(
                (item: any) => item?.value === valueFromFormik.value
            )
        ) {
            if (setValueToDefaultIfNotInList) {
                setFieldValue(defaultValue);
            }
        }
    }, [valueFromFormik, controlOptions]);

    return (
        <Autocomplete
            disableClearable={other.disableClearable}
            disabled={other.disabled}
            className={other.className}
            autoComplete
            multiple={multipleControl}
            id={name}
            value={controlValue}
            options={controlOptions}
            groupBy={
                groupByField ? (option) => option[groupByField] : undefined
            }
            getOptionLabel={(option: any) => {
                return option.label || option[`label-${language}`] || "";
            }}
            getOptionSelected={(option, value) => {
                return value.value === option.value;
            }}
            // inputValue={(controlValue || {}).label || ""}
            onChange={(event: any, newValue: any | null) => {
                if (
                    newValue === null ||
                    newValue === undefined ||
                    newValue === ""
                ) {
                    setFieldValue(defaultValue);
                } else {
                    setFieldValue(newValue);
                }
                if (
                    childrenFilterFieldName !== undefined &&
                    childrenFilterFieldName !== ""
                ) {
                    if (childrenFilterFieldName.includes(",")) {
                        childrenFilterFieldName
                            .split(",")
                            .map((item: string) => {
                                if (Array.isArray(childrenFieldValue)) {
                                    setValue(item, []);
                                } else {
                                    setValue(item, defaultValue);
                                }
                            });
                    } else {
                        if (Array.isArray(childrenFieldValue)) {
                            setValue(childrenFilterFieldName, []);
                        } else {
                            setValue(childrenFilterFieldName, defaultValue);
                        }
                    }
                }
            }}
            onBlur={field.onBlur}
            renderInput={(params: any) => (
                <TextField
                    {...(other?.inputProps || {})}
                    {...params}
                    error={error}
                    required={required}
                    label={label}
                    // margin="normal"
                    helperText={message}
                    autoComplete="new-password"
                    data-testid={field.name}
                    // {...field}
                    inputRef={field.ref}
                />
            )}
        />
    );
};

export default ComboAutoComplete;
