import React, { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import FormLabel from "@material-ui/core/FormLabel";
import Paper from "@material-ui/core/Paper";
import { TransformSchema } from "../utils";
import { useHandleDropzone } from "./useHandleDropzone";
import { el } from "date-fns/locale";

export interface IImportSettingsSteps {
    file: File | null;
    setFile: React.Dispatch<React.SetStateAction<File | null>>;
    transformSchema: TransformSchema | null;
    setTransformSchema: React.Dispatch<
        React.SetStateAction<TransformSchema | null>
    >;
    fullData: Compleo.IObject[];
    setFullData: React.Dispatch<React.SetStateAction<Compleo.IObject[]>>;
    t: any;
    tBulk: any;
    language: string;
    templateDbData: Compleo.IObject | null;
    setTemplateDbData: React.Dispatch<
        React.SetStateAction<Compleo.IObject | null>
    >;
    setActiveStep: (value: React.SetStateAction<number>) => void;
    onClose: (value?: string) => void;
    handleNext: (ignoreValidation?: boolean) => Promise<void>;
    handleBack: () => Promise<void>;
    allLinesAreValid: boolean;
    setAllLinesAreValid: React.Dispatch<React.SetStateAction<boolean>>;
    importApplicantsLimit: number;
}

export function ImportSettingsStep1(props: IImportSettingsSteps) {
    const { setFile, handleNext } = props;
    const { dropzoneProps, element } = useHandleDropzone(props);
    const { acceptedFiles } = dropzoneProps;

    useEffect(() => {
        const handleAcceptedFiles = async () => {
            setFile(acceptedFiles[0]);
            handleNext();
        };
        if (acceptedFiles.length > 0) {
            handleAcceptedFiles();
        }
    }, [acceptedFiles]);

    return <>{element}</>;
}
