import React from "react";
import Page from "Pages/_Layouts/DefaultInternal";
import useCompleoReactHookForm, {
    useCompleoReactHookFormGetMainData
} from "customHooks/useCompleoReactHookForm";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import FormClassic from "customHooks/useCompleoReactHookForm/components/FormClassic";
import { apiDirectCall, ApiStateType } from "customHooks/useApi";
import useValuesFromSource from "customHooks/useValuesFromSource";
import { convertParserToCompleoPattern } from "functions/util";
import _ from "lodash";
import ApplicantContainerCV from "./ApplicantContainerCV";
import useApplicantNewContext from "./useApplicantNewContext/useApplicantNewContext";
import { navigate } from "@gatsbyjs/reach-router";
import { useQueryClient } from "react-query";
import useGetLanguagesCareers from "customHooks/useGetLanguages/useGetLanguages";

interface IApplicantNewDataEntry {}

const ApplicantNewDataEntry = (props: IApplicantNewDataEntry) => {
    const [data] = useApplicantNewContext();
    const { ApplicantFirstData } = data.localStateData;
    const queryClient = useQueryClient();
    const [
        languages,
        defaultLanguage,
        readyLanguages
    ] = useGetLanguagesCareers();
    const listDefinition: Compleo.CustomLists.ListDefinitionType[] = [];

    // const { company } = useAuthState();

    const moduleName = "APPLICANTNEW";
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });

    const defaultFieldsToHide: string[] = [];

    const postAddress = `/applicant/new`;
    // const [addUserReturn, addUser] = useApi(postAddress, "post");

    const [metadata] = useGetMetadata(moduleName);
    let [valuesFromSource] = useValuesFromSource(metadata, true, {});

    if (ApplicantFirstData) {
        if (data.localStateData?.jsonParsedCV) {
            valuesFromSource = convertParserToCompleoPattern(
                valuesFromSource,
                data.localStateData?.jsonParsedCV || {},
                true
            );
        }
        valuesFromSource.deleted = false;
        valuesFromSource.name = ApplicantFirstData.name || "";
        valuesFromSource.email = ApplicantFirstData.email || "";
        valuesFromSource.cpf = ApplicantFirstData.cpf || "";
        if (ApplicantFirstData.jobId) {
            valuesFromSource.jobToAddApplicant = {
                jobId: ApplicantFirstData.jobId,
                stage: ApplicantFirstData.stage,
                disqualifyReason: ApplicantFirstData.disqualifyReason,
                ignoreAutomation: ApplicantFirstData.ignoreAutomation
            };
        }

        if (valuesFromSource.cpf === "") {
            defaultFieldsToHide.push("cpf");
        }
        if (data.localStateData.cvFile?.streamContent) {
            valuesFromSource.cv = {
                FileInfo: data.localStateData.cvFile?.streamContent,
                tags: []
            };
            defaultFieldsToHide.push("cv");
        }
        if (data.localStateData.jsonParsedCV?.TxtCV) {
            valuesFromSource.cvText = data.localStateData.jsonParsedCV?.TxtCV;
        }
    }
    if (!valuesFromSource.disabledPerson) {
        defaultFieldsToHide.push("typeOfDisability");
    }

    // se só tiver 1 idioma, esconde o campo
    if (readyLanguages && (languages || []).length < 2) {
        defaultFieldsToHide.push("preferredLanguage");
    }

    if (readyLanguages) {
        const defaultLanguageData = languages.filter(
            (item: Compleo.IObject) => item.value === defaultLanguage
        )[0] || { value: defaultLanguage };
        valuesFromSource.preferredLanguage = defaultLanguageData;
        if (
            listDefinition.filter(
                (item) => item.fieldName === "preferredLanguage"
            ).length === 0
        ) {
            listDefinition.push({
                data: languages,
                fieldName: "preferredLanguage",
                valueName: "value",
                labelName: "label"
            });
        }
    }

    const [fieldsToHide, setFieldsToHide] = React.useState<string[]>(
        defaultFieldsToHide
    );

    const reactHookFormMethods = useCompleoReactHookFormGetMainData({
        t,
        ready: readyTranslation && readyLanguages,
        i18nV: i18n,
        metadadosRetorno: metadata,
        valuesFromSource
    });
    const { watch } = reactHookFormMethods.reactHookFormMethods;
    const disabledPersonField = watch("disabledPerson");
    React.useEffect(() => {
        let fieldsToHideFN = defaultFieldsToHide;
        if (disabledPersonField) {
            fieldsToHideFN = defaultFieldsToHide.filter(
                (item) => item !== "typeOfDisability"
            );
        }
        if (!_.isEqual(fieldsToHide, fieldsToHideFN)) {
            setFieldsToHide(fieldsToHideFN);
        }
    }, [disabledPersonField]);

    const handleSubmit = async (values: any) => {
        try {
            const dataInsert = await apiDirectCall(postAddress, "post", {
                ...values
            });
            queryClient.invalidateQueries("/applicant/list");
            if (data.operationid && data.itemid) {
                const postAddressParserUpdate = `/parser/parseroperationdetaileditstatus`;
                const dataParserUpdate = await apiDirectCall(
                    postAddressParserUpdate,
                    "post",
                    {
                        operationId: data.operationid,
                        itemId: data.itemid,
                        companyId: values.companyId,
                        status: "finished",
                        applicantId: dataInsert.data.applicantAutoIncrement
                    }
                );
            }
            if (data.operationid) {
                navigate(`/applicant/parserlistdetail/${data.operationid}`);
            } else {
                navigate(`/applicant/list`);
            }
        } catch (ex) {
            return ex.response;
        }
        const retorno: Compleo.IObject = {};
        retorno.status = 200;
        return retorno;
    };

    const postReturn: ApiStateType = {
        status: "success",
        response: {},
        exception: null
    };
    const [formCompleo, finished] = useCompleoReactHookForm({
        t,
        ready:
            readyTranslation && readyLanguages && metadata.status === "success",
        i18nV: i18n,
        postMethod: handleSubmit,
        postReturn: postReturn,
        FormType: FormClassic,
        metadadosRetorno: metadata,
        valuesFromSource,
        fieldsToHide,
        sizeToChangeStepHorizontal: "lg",
        customLists: listDefinition,
        reactHookFormMethods: reactHookFormMethods,
        forceBackdrop: true
    });

    let compRetorno: boolean | JSX.Element;
    compRetorno = <Loading />;

    if (finished && readyTranslation) {
        compRetorno = formCompleo;
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
                maxWidth={data.localStateData?.urlCV ? "xl" : "md"}
            >
                <ApplicantContainerCV
                    compRetorno={compRetorno}
                    url={data.localStateData?.urlCV}
                    pageMaxWidth="xl"
                />
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

// UserNew.whyDidYouRender = true;
export default ApplicantNewDataEntry;

export const getFieldsToHide = (defaultFieldsToHide: any) => {
    return defaultFieldsToHide;
};
