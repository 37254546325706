import { useApiCache } from "customHooks/useApi";
import React, { useReducer } from "react";
import { useAuthState } from "_ReactContext/AuthContext";

const useJobTitleList = () => {
    const [listDefinition, setListDefinition] = React.useState<
        [Compleo.CustomLists.ListDefinitionType | null, boolean]
    >([null, false]);
    const { company } = useAuthState();

    const postAddressSearch = `/jobtitle/jobtitledropdownlist`;
    const [jobTitleListReturn] = useApiCache(
        postAddressSearch,
        "post",
        {
            companyId: company.companyId
        },
        false
    );

    React.useEffect(() => {
        if (
            jobTitleListReturn.status === "success" &&
            listDefinition[1] === false
        ) {
            const data: Compleo.IObject[] = [];
            (jobTitleListReturn.response?.data?.dropdown || []).map(
                (item: any) => {
                    data.push(item);
                }
            );
            setListDefinition([
                {
                    data: data,
                    fieldName: "title",
                    labelName: "itemValue",
                    valueName: "itemText"
                },
                true
            ]);
        }
    }, [jobTitleListReturn.status]);

    return {
        listDefinition,
        fields: jobTitleListReturn.response?.data?.fields || []
    };
};

export default useJobTitleList;
