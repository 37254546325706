import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {
    IImportSettingsSteps,
    ImportSettingsStep1
} from "./steps/ImportSettingsStep1";
import { getDistinctValues, mappingElements, TransformSchema } from "./utils";
import { ImportSettingsStep2 } from "./steps/ImportSettingsStep2";
import { ImportSettingsStep3 } from "./steps/ImportSettingsStep3";
import { ImportSettingsStep4 } from "./steps/ImportSettingsStep4";
import { ImportSettingsStep5 } from "./steps/ImportSettingsStep5";
import { useImportSettings } from "./steps/useImportSettings";
import { useSyncImportApplicantTemplate } from "./steps/useSyncImportApplicantTemplate";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";
import { FormHelperText } from "@material-ui/core";
import { useFormContext } from "react-hook-form";
import { useAuthState } from "_ReactContext/AuthContext";
import { apiDirectCall } from "customHooks/useApi";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import useList from "customHooks/useList";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%"
        },
        button: {
            marginRight: theme.spacing(1)
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1)
        }
    })
);

function getSteps(t: any) {
    return [
        t("step1_description"),
        t("step2_description"),
        t("step3_description"),
        t("step4_description"),
        t("step5_description")
    ];
}

interface IProps {
    label?: string;
    metadata: any;
    fieldNameTrans?: string;
    helperTextDefault?: string;
    nextStep: () => void;
    previousStep: () => void;
    onClose: (value?: string) => void;
    handleSubmit: (values: any) => Promise<any>;
    tBulk: any;
}
export function ImportSettings(props: IProps) {
    const { handleSubmit, onClose, tBulk, metadata } = props;
    const { readyForm, callDialog, agree } = useGlobalDialog();
    const [errorMessage, setErrorMessage] = useState<string>("");
    const { company } = useAuthState();
    const [listData] = useList();
    const maxItems =
        listData?.definitionInfo?.elasticDefData?.data?.otherSettings
            ?.bulkActionsSettings?.maxItems?.applicant_importFromFile || 1000;

    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [allLinesAreValid, setAllLinesAreValid] = React.useState(false);
    const steps = getSteps(tBulk);
    const [fullData, setFullData] = useState<Compleo.IObject[]>([]);
    const [
        templateDbData,
        setTemplateDbData
    ] = useState<Compleo.IObject | null>(null);
    const { t, ready, dbFields } = useImportSettings();

    const [file, setFile] = useState<File | null>(null);
    const [
        transformSchema,
        setTransformSchema
    ] = useState<TransformSchema | null>(null);
    const { syncImportApplicantTemplateData } = useSyncImportApplicantTemplate(
        { templateDbData, transformSchema },
        false
    );
    const methods = useFormContext();

    const submit = async () => {
        if (!file) {
            return;
        }
        const values = methods.getValues();
        const formData = new FormData();
        formData.append("file", file);
        formData.append("importSettings", JSON.stringify(transformSchema));
        formData.append("companyId", company.companyId.toString());
        if (values.name) {
            formData.append("name", values.name.toString());
        }
        formData.append("job", JSON.stringify(values.job));
        formData.append("stage", JSON.stringify(values.stage));
        formData.append(
            "disqualifyReason",
            JSON.stringify(values.disqualifyReason)
        );
        formData.append("type", "applicant_importFromFile");
        formData.append("overrideTotalItems", fullData.length.toString());
        const response = await apiDirectCall(
            "/bulkactions/baadd",
            "post",
            formData,
            {
                headers: { "Content-Type": "multipart/form-data" }
            }
        );
        const newValues = {
            ...values,
            importSettings: transformSchema,
            templateDbData: templateDbData,
            file: file
        };
        await handleSubmit(newValues);
    };

    const handleValidation = (step: number) => {
        let errorMessage = "";
        switch (step) {
            case 0:
                if (!file) {
                    errorMessage = tBulk("errorSelectAFile");
                }
                break;
            case 1:
                if (!templateDbData) {
                    errorMessage = tBulk("errorSelectATemplate");
                }
                break;
            case 2:
                {
                    const totalMapped = (transformSchema?.map || []).filter(
                        (i) => i.dbColumnName
                    );
                    if (totalMapped.length === 0) {
                        errorMessage = tBulk("errorDefineFieldMapping");
                    }
                }
                break;
            case 3:
                {
                    const columns = (transformSchema?.map || []).filter(
                        (i) => i.dbColumnName
                    );
                    let totalNotMappedLines = 0;
                    for (const column of columns) {
                        const fieldData = dbFields.find(
                            (field) => field.id === column.dbColumnName
                        );
                        if (
                            mappingElements.includes(
                                fieldData?.fullDef?.elementType
                            )
                        ) {
                            const values = getDistinctValues(
                                fullData,
                                column.columnName
                            );
                            const mappedValues = (column.mapValues || []).map(
                                (i) => i.fileValue
                            );
                            const valuesNotMapped = values.filter(
                                (value) => !mappedValues.includes(value)
                            );

                            if (valuesNotMapped.length > 0) {
                                totalNotMappedLines += 1;
                            }
                        }
                    }
                    if (totalNotMappedLines > 0) {
                        errorMessage = tBulk("errorDefineContentMapping");
                    }
                }
                break;
            case 4:
                if (!allLinesAreValid) {
                    errorMessage = tBulk("preCheck_errorMessage");
                } else {
                    submit();
                }
                break;
            default:
                return true;
        }
        setErrorMessage(errorMessage);
        if (errorMessage) {
            return false;
        }
        return true;
    };
    const handleNext = async (ignoreValidation = true) => {
        if (handleValidation(activeStep) || ignoreValidation) {
            if (activeStep >= 2) {
                await syncImportApplicantTemplateData({
                    templateDbData: templateDbData!,
                    transformSchema: transformSchema!
                });
            }
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        if (ignoreValidation) {
            setErrorMessage("");
        }
    };

    const handleBack = async () => {
        if (activeStep >= 2) {
            await syncImportApplicantTemplateData({
                templateDbData: templateDbData!,
                transformSchema: transformSchema!
            });
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const childrenProps: IImportSettingsSteps = {
        file,
        setFile,
        transformSchema,
        setTransformSchema,
        fullData,
        setFullData,
        t,
        tBulk,
        language: "pt-BR",
        templateDbData,
        setTemplateDbData,
        setActiveStep,
        onClose,
        handleNext,
        handleBack,
        allLinesAreValid: allLinesAreValid,
        setAllLinesAreValid,
        importApplicantsLimit: maxItems
    };

    return (
        <>
            <div className={classes.root}>
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => {
                        const stepProps: { completed?: boolean } = {};
                        const labelProps: { optional?: React.ReactNode } = {};

                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                {errorMessage && (
                    <FormHelperText error={true}>{errorMessage}</FormHelperText>
                )}
                <div>
                    <div>
                        <Typography className={classes.instructions}>
                            {(() => {
                                switch (activeStep) {
                                    case 0:
                                        return (
                                            <ImportSettingsStep1
                                                {...childrenProps}
                                            />
                                        );
                                    case 1:
                                        return (
                                            <ImportSettingsStep2
                                                {...childrenProps}
                                            />
                                        );
                                    case 2:
                                        return (
                                            <ImportSettingsStep3
                                                {...childrenProps}
                                            />
                                        );
                                    case 3:
                                        return (
                                            <ImportSettingsStep4
                                                {...childrenProps}
                                            />
                                        );
                                    case 4:
                                        return (
                                            <ImportSettingsStep5
                                                {...childrenProps}
                                            />
                                        );
                                    default:
                                        return <Loading />;
                                }
                            })()}
                            {/* {getStepContent(activeStep)} */}
                        </Typography>
                        <div style={{ marginTop: 24 }}>
                            <Button
                                onClick={() => {
                                    callDialog({
                                        title: tBulk("cancelImport_title"),
                                        bodyText: tBulk("cancelImport_message"),
                                        agreeButtonText: t(
                                            "COMPLEOGENERAL_YES"
                                        ),
                                        disagreeButtonText: t(
                                            "COMPLEOGENERAL_NO"
                                        ),
                                        agreeFunction: async () => {
                                            onClose();
                                        },
                                        disagreeFunction: () => {}
                                    });
                                }}
                                className={classes.button}
                                variant="contained"
                                color="secondary"
                            >
                                {t("COMPLEOGENERAL_CANCEL")}
                            </Button>
                            <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                className={classes.button}
                            >
                                {t("COMPLEOGENERAL_PREVIOUS")}
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    handleNext(false);
                                }}
                                className={classes.button}
                            >
                                {activeStep === steps.length - 1
                                    ? t("COMPLEOGENERAL_FINISH")
                                    : t("COMPLEOGENERAL_NEXT")}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
