import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Paper from "@material-ui/core/Paper";
import { useDropzone } from "react-dropzone";

import { TransitionProps } from "@material-ui/core/transitions";

import { IImportSettingsSteps } from "../ImportSettingsStep1";
import FormLabel from "@material-ui/core/FormLabel";
import { useHandleDropzone } from "../useHandleDropzone";
import { Alert } from "@material-ui/lab";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

type MapContentProps = IImportSettingsSteps & {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export function UploadNewFileDialog(props: MapContentProps) {
    const { open, setOpen, setActiveStep, setFile, tBulk } = props;

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = () => {};
    const { dropzoneProps, element } = useHandleDropzone(props);
    const { acceptedFiles } = dropzoneProps;

    useEffect(() => {
        const handleAcceptedFiles = async () => {
            setFile(acceptedFiles[0]);
            setActiveStep(2);
            handleClose();
        };
        if (acceptedFiles.length > 0) {
            handleAcceptedFiles();
        }
    }, [acceptedFiles]);

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">
                {tBulk("preCheck_selectNewFile")}
            </DialogTitle>
            <DialogContent>
                <Alert severity="info" style={{ marginBottom: "20px" }}>
                    {tBulk("preCheck_redirectAfterUpload")}
                </Alert>
                <>{element}</>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {tBulk("COMPLEOGENERAL_CANCEL")}
                </Button>
                <Button onClick={handleSave} color="primary">
                    {tBulk("COMPLEOGENERAL_SAVE")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
