import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {
    Checkbox,
    FormControlLabel,
    TextField,
    InputAdornment,
    Typography,
    Grid,
    RadioGroup,
    Radio,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider
} from "@material-ui/core";
import { FixedSizeList as ListReactWindow } from "react-window";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import { matchSorter } from "match-sorter";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import { useRhfFieldControlled } from "customHooks/useCompleoReactHookForm/helpers/reactHookFormsHelper";
import BulkActionsList from "../../BulkActionsList";
import { useTranslation } from "react-i18next";
import { useImportSettings } from "../../BulkActionsStep3ImportApplicants/components/steps/useImportSettings";
import { useApiCache } from "customHooks/useApi";
import { useAuthState } from "_ReactContext/AuthContext";
import { NewExportTemplateFieldsDialog } from "./newExportApplicantTemplate";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        instructions: {
            marginBottom: theme.spacing(3)
        },
        textSearch: {
            // marginTop: theme.spacing(1),
            marginBottom: theme.spacing(2)
        },
        radioGroup: {
            marginBottom: theme.spacing(1)
        },
        nested: {
            padding: theme.spacing(0.1),
            margin: theme.spacing(0.1),
            paddingLeft: theme.spacing(4)
        },
        listItem: {
            minWidth: theme.spacing(0.1),
            padding: 0,
            margin: 0
        },
        checkBox: {
            padding: theme.spacing(0.2),
            paddingRight: theme.spacing(0.4)
            // margin: theme.spacing(1)
        }
    })
);

type RadioValuesType = "all" | "selected" | "unselected";

interface IProps {
    t: any;
}

export function FieldsToExport(props: IProps) {
    const { t } = props;
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { company } = useAuthState();
    const [open, setOpen] = React.useState(false);
    const [selectAllChecked, setSelectAllChecked] = React.useState(true);
    const { setFieldValue, field } = useRhfFieldControlled("fieldsToExport");
    const data: string[] = field.value || [];
    const [templates, dataStatus] = useApiCache(
        `/exportimportdata/applicant-export-template-list`,
        "post",
        {
            companyId: company.companyId
        },
        false
    );
    const templatesData: Compleo.IObject[] =
        templates?.response?.data?.items || [];

    const [textSearch, setTextSearch] = React.useState("");
    const [radioValue, setRadioValue] = React.useState<RadioValuesType>("all");
    const { ready, dbFields, t: tLocal } = useImportSettings({
        returnOnlyBaseComplexFields: true
    });
    const allFields: string[] = ready
        ? dbFields.map((i) => i.fullDef?.fieldName)
        : [];
    sortFields(allFields, tLocal);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTextSearch(event.target.value);
    };

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue: RadioValuesType = event.target.value as RadioValuesType;
        setRadioValue(newValue);
    };

    const totalItems = allFields.length;
    const selectedItems = data.length;

    const changeItemChecked = (value: string, checked: boolean) => {
        const uniqueValues = [...new Set(data)];

        if (checked) {
            setFieldValue([...uniqueValues, value]);
        } else {
            setFieldValue(uniqueValues.filter((item) => item !== value));
        }
    };

    React.useEffect(() => {
        if (selectAllChecked && totalItems !== selectedItems) {
            setSelectAllChecked(false);
        }
        if (!selectAllChecked && totalItems === selectedItems) {
            setSelectAllChecked(true);
        }
    }, [totalItems, selectedItems]);

    const handleSelectAll = (currentValue: boolean) => {
        const newValue = !currentValue;
        if (newValue) {
            setFieldValue(allFields);
        } else {
            setFieldValue([]);
        }
    };

    function matchFields(textSearch: string) {
        const filteredData = matchSorter(dbFields, textSearch, {
            keys: ["label"]
        });
        const result = filteredData.map((i) => i.fullDef?.fieldName);
        return sortFields(result, tLocal);
    }

    /**
     * Filter by text search
     */
    const filteredTextArray: string[] = textSearch
        ? matchFields(textSearch)
        : allFields;

    /**
     * Show all, selected or unselected options
     */
    const filteredArray: string[] = filteredTextArray.filter((item) => {
        switch (radioValue) {
            case "unselected":
                return !data.includes(item);
            case "selected":
                return data.includes(item);
            default:
                return true;
        }
    });

    const updateFromModel = (value: string[]) => {
        // check if all values exist in the model
        const newValue = value.filter((item) => allFields.includes(item));
        // remove any duplicates
        setFieldValue([...new Set(newValue)]);
    };

    const Row = ({ index, style }: any) => {
        const item = filteredArray[index];

        return (
            <div style={style}>
                <ListItem
                    className={classes.nested}
                    style={{ width: "98%" }}
                    button
                    onClick={() =>
                        changeItemChecked(item, !data.includes(item))
                    }
                >
                    <ListItemIcon className={classes.listItem}>
                        <Checkbox
                            edge="start"
                            checked={data.includes(item)}
                            tabIndex={-1}
                            disableRipple
                            className={classes.checkBox}
                            size="small"
                        />
                    </ListItemIcon>
                    <ListItemText
                        className={classes.listItem}
                        secondary={tLocal(item)}
                    />
                </ListItem>
            </div>
        );
    };

    if (totalItems > 0) {
        return (
            <>
                <NewExportTemplateFieldsDialog
                    open={open}
                    setOpen={setOpen}
                    fields={data}
                />
                <Grid container>
                    <Grid
                        item
                        container
                        xs={12}
                        // alignContent="center"
                        alignItems="center"
                    >
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selectAllChecked}
                                        onChange={() =>
                                            handleSelectAll(selectAllChecked)
                                        }
                                    />
                                }
                                label={t(
                                    "ELASTICLISTSETTINGS:ModalBulkActions_SelectAll"
                                )}
                            />
                        </Grid>
                        <Grid
                            container
                            item
                            xs={12}
                            sm={6}
                            justify="flex-end"
                            alignContent="center"
                            alignItems="center"
                        >
                            <Menu
                                id="sort-elastic-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={() => setAnchorEl(null)}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "left"
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right"
                                }}
                            >
                                <MenuItem
                                    onClick={() => {
                                        setOpen(true);
                                        setAnchorEl(null);
                                    }}
                                >
                                    {t("saveAsTemplate")}
                                </MenuItem>

                                {templatesData.length > 0 && (
                                    <>
                                        <Divider />
                                        <MenuItem disabled>
                                            {t("loadTemplate")}
                                        </MenuItem>
                                    </>
                                )}
                                {templatesData.map((option: any) => {
                                    return (
                                        <MenuItem
                                            key={option.uuid}
                                            onClick={() => {
                                                updateFromModel(
                                                    option.fields || []
                                                );
                                                setAnchorEl(null);
                                            }}
                                        >
                                            {option.name}
                                        </MenuItem>
                                    );
                                })}
                            </Menu>
                            <Typography>
                                {t(
                                    "ELASTICLISTSETTINGS:ModalBulkActions_SelectedOf",
                                    {
                                        selected: selectedItems,
                                        total: totalItems
                                    }
                                )}
                            </Typography>
                            <ButtonIconTooltipCompleo
                                label="Options"
                                onClick={(e) => setAnchorEl(e.currentTarget)}
                            >
                                <MoreHoriz />
                            </ButtonIconTooltipCompleo>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12}>
                        <RadioGroup
                            // aria-label="gender"
                            // name="gender1"
                            value={radioValue}
                            onChange={handleRadioChange}
                            row
                            className={classes.radioGroup}
                        >
                            <FormControlLabel
                                value="all"
                                control={<LocalRadio />}
                                label={t(
                                    "ELASTICLISTSETTINGS:ModalBulkActions_All"
                                )}
                            />
                            <FormControlLabel
                                value="selected"
                                control={<LocalRadio />}
                                label={t(
                                    "ELASTICLISTSETTINGS:ModalBulkActions_Selected"
                                )}
                            />
                            <FormControlLabel
                                value="unselected"
                                control={<LocalRadio />}
                                label={t(
                                    "ELASTICLISTSETTINGS:ModalBulkActions_Unselected"
                                )}
                            />
                        </RadioGroup>
                    </Grid>
                    <Grid item container xs={12}>
                        <TextField
                            className={classes.textSearch}
                            placeholder={t(
                                "ELASTICLISTSETTINGS:searchBucketPlaceholder"
                            )}
                            fullWidth
                            value={textSearch}
                            onChange={handleChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <ButtonIconTooltipCompleo
                                            label="Clear"
                                            onClick={() => setTextSearch("")}
                                        >
                                            <CloseIcon />
                                        </ButtonIconTooltipCompleo>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>

                    <Grid item container xs={12}>
                        <ListReactWindow
                            className="List"
                            height={300}
                            itemCount={filteredArray.length}
                            itemSize={25}
                            width="100%"
                        >
                            {Row}
                        </ListReactWindow>
                    </Grid>
                </Grid>
            </>
        );
    } else {
        return <Loading />;
    }
}

function sortFields(fields: string[], t: any) {
    return fields.sort((a, b) => {
        const labelA = t(a);
        const labelB = t(b);
        return labelA.localeCompare(labelB);
    });
}

const LocalRadio = (props: any) => {
    return <Radio size="small" color="primary" {...props} />;
};

export default BulkActionsList;
