import { v4 } from "uuid";
import { addYears, addDays, isValid, toDate, formatRelative } from "date-fns";
import * as formatValues from "./formatValues";
import { currencyLanguageTypes } from "./formatValues";
// import { formatMapCurrency } from "customHooks/useCompleoForm/Inputs/CurrencyField";

export const uuid = () => {
    return v4();
};

/**
 * limpa um objeto. Exemplo: de = campo.0.value para campo.value
 * ou de = campo[0].value para campo.value
 * @param value nome do campo a ser alterado
 * @returns
 */
export const clearArrayInfo = (value: string) => {
    if ((value || "").includes("[")) {
        const insideBrackets = value.match(/\[(.*?)\]/);
        if (insideBrackets === null) {
            return value;
        } else {
            const valueToReplace = insideBrackets[0];
            return value.replace(valueToReplace, "");
        }
    } else {
        const insideBrackets = value.match(/\.(.*?)\./);
        if (insideBrackets === null) {
            return value;
        } else {
            const valueToReplace = insideBrackets[0];
            return value.replace(valueToReplace, ".");
        }
    }
};

export const toBase64 = (file: any) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            if (typeof reader.result === "string") {
                resolve(reader.result.split(",")[1]);
            } else {
                reject({ message: "Error converting to base64 string" });
            }
        };
        reader.onerror = (error) => reject(error);
    });

function parsePhoneNumber(
    input: string
): {
    countryCode: string;
    ddd: string;
    phoneNumber: string;
} {
    // Remove all non-digit characters except the leading '+'
    const cleanedInput = (input || "").replace(/(?!^\+)\D/g, "");
    let countryCode = "+55"; // Default country code
    let ddd = "";
    let phoneNumber = "";

    if (cleanedInput.startsWith("+")) {
        // International format
        const match = cleanedInput.match(/^\+(\d{1,3})(\d{2})(\d+)$/);
        if (match) {
            [, countryCode, ddd, phoneNumber] = match;
            countryCode = "+" + countryCode;
        } else {
            // If it doesn't match the expected format, treat as Brazilian number
            ddd = cleanedInput.slice(1, 3);
            phoneNumber = cleanedInput.slice(3);
        }
    } else if (cleanedInput.startsWith("55")) {
        // Brazilian number with country code, but without '+'
        countryCode = "+55";
        ddd = cleanedInput.slice(2, 4);
        phoneNumber = cleanedInput.slice(4);
    } else {
        // Local Brazilian number
        ddd = cleanedInput.slice(0, 2);
        phoneNumber = cleanedInput.slice(2);
    }

    // If the phone number is 8 digits, add a '9' prefix (for Brazilian numbers)
    if (countryCode === "+55" && phoneNumber.length === 8) {
        phoneNumber = "9" + phoneNumber;
    }

    return { countryCode, ddd, phoneNumber };
}

/**
 * IMPORTANT: this function already exists on backend, so if you change here, change there too
 */
export const convertParserToCompleoPattern = (
    compleoValues: Compleo.IObject,
    parserResult: CompleoShared.ParserResult.IApplicant,
    replaceValues: boolean = false
) => {
    const replaceValue = (
        origin?: any,
        newObj?: any,
        replaceValues: boolean = false
    ) => {
        if (replaceValues) {
            return newObj !== undefined ? newObj : origin;
        } else {
            return origin !== undefined ? origin : newObj;
        }
    };

    const processDate = (parserDate?: {
        day: number;
        month: number;
        year: number;
    }) => {
        if (parserDate !== undefined && typeof parserDate === "object") {
            const newDate = new Date(
                parserDate.year,
                (parserDate.month || 2) - 1,
                parserDate.day || 1
            );
            return newDate.toISOString();
        } else {
            return null;
        }
    };

    const processEducationLevel = (parserLevel?: string) => {
        switch (parserLevel) {
            case "Basico":
                return {
                    "label-en-US": "Basic",
                    "label-pt-BR": "Básico",
                    label: "Básico",
                    value: "CP_Basic"
                };
            case "Tecnico Segundo Grau":
                return {
                    "label-en-US": "Technician",
                    label: "Técnico",
                    "label-pt-BR": "Técnico",
                    value: "CP_Technician"
                };
            case "Superior":
                return {
                    "label-en-US": "Superior",
                    "label-pt-BR": "Superior",
                    label: "Superior",
                    value: "CP_Superior"
                };
            case "MBA":
                return {
                    "label-en-US": "MBA",
                    "label-pt-BR": "MBA",
                    label: "MBA",
                    value: "CP_MBA"
                };
            case "Pos-Graduacao":
                return {
                    "label-en-US": "Postgraduate",
                    "label-pt-BR": "Pós-Graduação",
                    label: "Pós-Graduação",
                    value: "CP_Postgraduate"
                };
            case "Mestrado":
                return {
                    "label-en-US": "Master's degree",
                    "label-pt-BR": "Mestrado",
                    label: "Mestrado",
                    value: "CP_Master"
                };
            case "Doutorado":
                return {
                    "label-en-US": "Doctorate degree",
                    "label-pt-BR": "Doutorado",
                    label: "Doutorado",
                    value: "CP_Doctorate"
                };
            default:
                return {
                    "label-en-US": "Not Informed",
                    "label-pt-BR": "Não Informado",
                    label: "Não Informado",
                    value: "CP_NotInformed"
                };
        }
    };

    const processEducationStatus = (parserStatus?: string) => {
        switch (parserStatus) {
            case "Concluido":
                return {
                    "label-en-US": "Completed",
                    "label-pt-BR": "Completo",
                    label: "Completo",
                    value: "CP_Completed"
                };
            case "Trancado":
                return {
                    "label-en-US": "Not completed",
                    "label-pt-BR": "Incompleto",
                    label: "Incompleto",
                    value: "CP_NotCompleted"
                };
            case "Andamento":
                return {
                    "label-en-US": "In progress",
                    "label-pt-BR": "Cursando",
                    label: "Cursando",
                    value: "CP_InProgress"
                };
            default:
                return {
                    "label-en-US": "In progress",
                    "label-pt-BR": "Cursando",
                    label: "Cursando",
                    value: "CP_InProgress"
                };
        }
    };

    const processMobile = (value?: string) => {
        if (Array.isArray(value)) {
            const dddResult = keepOnlyNumbersInString((value || [])[0]);
            const mobileNumber = keepOnlyNumbersInString((value || [])[1]);
            if (dddResult && mobileNumber) {
                return `+55${dddResult}${mobileNumber}`;
            }
            if (!dddResult && mobileNumber) {
                return `+55${mobileNumber}`;
            }
        }
        if (value?.startsWith("+")) {
            return value;
        }
        const { countryCode, ddd, phoneNumber } = parsePhoneNumber(value || "");
        if (ddd && phoneNumber) {
            return `${countryCode}${ddd}${phoneNumber}`;
        }
        return undefined;
    };

    const educationFromParser:
        | CompleoShared.Applicant.IEducation[]
        | undefined = ((parserResult || {}).Formacao || []).map(
        (f: CompleoShared.ParserResult.IFormacao) => {
            const objReturn: CompleoShared.Applicant.IEducation = {
                schoolName: (f?.Escola || "").substring(0, 99),
                courseName: (f?.Curso || "").substring(0, 99),
                startDate: processDate(f.Inicio),
                endDate: processDate(f.Termino),
                level: processEducationLevel(f.Tipo),
                status: processEducationStatus(f.Status)
            };
            return objReturn;
        }
    );

    const historyFromParser:
        | CompleoShared.Applicant.IProfessionalHistory[]
        | undefined = ((parserResult || {}).Historico || []).map(
        (f: CompleoShared.ParserResult.IHistorico) => {
            const objReturn: CompleoShared.Applicant.IProfessionalHistory = {
                companyName: (f?.Empresa || "").substring(0, 99),
                position: (f?.Cargo || "").substring(0, 99),
                startDate: processDate(f.Inicio),
                endDate: processDate(f.Termino),
                currentPosition: processDate(f.Termino) === null,
                notes: (f?.Atividades || "").substring(0, 499)
            };
            return objReturn;
        }
    );

    const returnObj: CompleoShared.Applicant.IApplicant = { ...compleoValues };
    returnObj.name = replaceValue(
        returnObj.name,
        parserResult.Nome,
        replaceValues
    );
    returnObj.email = replaceValue(
        returnObj.email,
        (parserResult.Email || [])[0],
        replaceValues
    );
    returnObj.email2 = replaceValue(
        returnObj.email,
        (parserResult.Email || [])[1],
        replaceValues
    );

    returnObj.educationHistory = replaceValue(
        returnObj.educationHistory,
        educationFromParser,
        replaceValues
    );

    returnObj.professionalHistory = replaceValue(
        returnObj.professionalHistory,
        historyFromParser,
        replaceValues
    );

    returnObj.mobileNumber = processMobile((parserResult?.Celular || [])[0]);
    returnObj.mobileNumber2 = processMobile((parserResult?.Celular || [])[1]);
    returnObj.landline = processMobile((parserResult?.Fixo || [])[0]);

    if (parserResult?.LinkedIn) {
        returnObj.linkedin = parserResult?.LinkedIn.includes("http")
            ? parserResult?.LinkedIn
            : `https://${parserResult?.LinkedIn}`;
    }

    if (parserResult?.ResumoQualificacoes) {
        returnObj.summaryOfQualifications = parserResult?.ResumoQualificacoes;
    }

    if (parserResult?.Endereco) {
        returnObj.location = {
            country: {
                label: "Brazil (BR)",
                value: "30"
            }
        };
        if (
            parserResult?.Endereco?.id_cidade &&
            parserResult?.Endereco?.cidade &&
            parserResult?.Endereco?.uf
        ) {
            returnObj.location.city = {
                label: parserResult?.Endereco?.cidade,
                value: parserResult?.Endereco?.id_cidade,
                uf: parserResult?.Endereco?.uf
            };
        }
        if (parserResult?.Endereco?.uf) {
            returnObj.location.provinceOrState = {
                value: parserResult?.Endereco?.uf,
                label: parserResult?.Endereco?.uf
            };
        }

        if (parserResult?.Endereco?.logradouro) {
            returnObj.location.addressline1 =
                parserResult?.Endereco?.logradouro;
        }
        if (parserResult?.Endereco?.complemento) {
            returnObj.location.addressline2 =
                parserResult?.Endereco?.complemento;
        }
        if (parserResult?.Endereco?.numero) {
            returnObj.location.number = parserResult?.Endereco?.numero;
        }
        if (parserResult?.Endereco?.cep) {
            let zipCode = parserResult?.Endereco?.cep || "";
            if (zipCode.length === 8) {
                zipCode = `${zipCode.slice(0, 5)}-${zipCode.slice(5)}`;
            }

            returnObj.location.postalCode = zipCode;
        }
        if (parserResult?.Endereco?.bairro) {
            returnObj.location.neighborhood = parserResult?.Endereco?.bairro;
        }
        if (parserResult?.Endereco?.latitude) {
            returnObj.location.latitude = parserResult?.Endereco?.latitude;
        }
        if (parserResult?.Endereco?.longitude) {
            returnObj.location.longitude = parserResult?.Endereco?.longitude;
        }
    }
    const returnData: CompleoShared.Applicant.IApplicant = {
        ...compleoValues,
        ...returnObj
    };
    return returnData;
};

export interface ITransformDBData {
    value: any;
    t: any;
    language: string;
    replaceOption?: string;
    customDateFormat?:
        | "dateWithTime"
        | "dateRelative"
        | "time"
        | "onlyMonthYear";
    customType?: "phone" | "phoneWhatsApp" | "photo";
    numberDecimalScale?: number;
    arrayJoinOption?: string;
}
export const transformDBData: (params: ITransformDBData) => any = (
    params: ITransformDBData
) => {
    const {
        value,
        t,
        language,
        replaceOption = "",
        customDateFormat,
        customType,
        numberDecimalScale,
        arrayJoinOption
    } = params;
    if (value === undefined || value === "" || value === null) {
        return replaceOption;
    }
    if (customType) {
        switch (customType) {
            case "phone":
            case "phoneWhatsApp":
                return formatValues.maskPhone(value);
            case "photo":
                return getPhotoTag(value);
            default:
                return value;
        }
    }

    switch (typeof value) {
        case "boolean":
            if (value.toString().toLowerCase() === "true") {
                return t("booleanTrue");
            } else {
                return t("booleanFalse");
            }
        case "object":
            if (!Object.keys(value).length) {
                return replaceOption;
            } else if (Array.isArray(value)) {
                if (value.length === 0) {
                    return replaceOption;
                } else {
                    switch (typeof value[0]) {
                        case "boolean":
                        case "object":
                            return value.map((item: any, index: number) => {
                                const isLastItem = value.length - 1 === index;
                                const addToFinal = isLastItem
                                    ? ""
                                    : arrayJoinOption;

                                return `${transformDBData({
                                    value: item,
                                    t,
                                    language,
                                    replaceOption,
                                    customDateFormat: customDateFormat
                                })} ${addToFinal || ""}`;
                            });
                        default: {
                            return value.map((item: any, index: number) => {
                                const isLastItem = value.length - 1 === index;
                                const addToFinal = isLastItem
                                    ? ""
                                    : arrayJoinOption;

                                return `${transformDBData({
                                    value: item,
                                    t,
                                    language,
                                    replaceOption,
                                    customDateFormat: customDateFormat
                                })} ${addToFinal || ""}`;
                            });
                        }
                    }
                }
            } else if (
                value?.value &&
                (value?.label || value[`label-${language}`])
            ) {
                const keyNames = Object.keys(value || {});
                const labelLanguage = `label-${language}`;
                const isList =
                    (keyNames.includes("label") ||
                        keyNames.includes(labelLanguage)) &&
                    keyNames.includes("value");
                if (isList) {
                    return value[labelLanguage] || value["label"];
                }
            } else if (value?.value) {
                if (value?.currency) {
                    return formatValues.maskCurrency(
                        value.value,
                        language as currencyLanguageTypes,
                        value?.currency,
                        numberDecimalScale
                    );
                } else {
                    return formatValues.maskNumber(
                        value.value,
                        language as currencyLanguageTypes,
                        numberDecimalScale
                    );
                }
            } else if (isValid(value)) {
                return formatValues.formatDate(value, language, "dayMonthYear");
            }
            return replaceOption;
        case "string":
            if (isValidIso8601(value)) {
                switch (customDateFormat) {
                    case "dateRelative":
                        return formatValues.formatDate(
                            value,
                            language,
                            "relative"
                        );
                    case "dateWithTime":
                        return formatValues.formatDate(
                            value,
                            language,
                            "dayMonthYearTime"
                        );
                    case "time":
                        return formatValues.formatDate(value, language, "time");
                    case "onlyMonthYear":
                        return formatValues.formatDate(
                            value,
                            language,
                            "monthYear"
                        );
                    default:
                        return formatValues.formatDate(
                            value,
                            language,
                            "dayMonthYear"
                        );
                }
            }
            return value;
        case "number":
            // return value;
            return formatValues.maskNumber(
                value,
                language as currencyLanguageTypes,
                numberDecimalScale
            );
    }
    if (typeof value === "object") {
        return replaceOption;
    }
    return value;
};

const getPhotoTag = (value: any) => {
    var imgSrc = `data:image/jpeg;base64,${value}`;
    return "<img src='" + imgSrc + "' style='width: 100px;' />";
};

export const isValidIso8601 = (value: string) => {
    if (isValid(new Date(value))) {
        const dateParsed = new Date(Date.parse(value));
        const iso8601Value = dateParsed.toISOString();
        if (value === iso8601Value) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
};

export const clearString = (str?: string, replaceWith: string = "") => {
    if (str) {
        return str.replace(/[^A-Z0-9]+/gi, replaceWith);
    }
    return str;
};

export const getExtension = (fileName: string) => {
    const re = /(?:\.([^.]+))?$/;
    return ((re.exec(fileName) || [])[1] || "").toLowerCase();
};

export const handleFileNameUpload = (value: string) => {
    if (typeof value === "string") {
        return clearFileName(clearAccentuatedString(value).replace(" ", "_"));
    } else {
        return value;
    }
};

export const clearAccentuatedString = (value: string) => {
    if (typeof value === "string") {
        return value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    } else {
        return value;
    }
};

export const clearAccentuatedStringFileUpload = (value: string) => {
    if (typeof value === "string") {
        return value
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .replace(" ", "_")
            .replace(/[^\w\s\\.]/gi, "");
    } else {
        return value;
    }
};

export const clearAccentuatedAndSpecialCharFromString = (value: string) => {
    if (typeof value === "string") {
        return value
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .replace(/[()$@%#/&*!:]/g, "");
    } else {
        return value;
    }
};

export const clearFileName = (value: string) => {
    const strClean1 = clearAccentuatedString(value);
    const fileNameCleared = strClean1.replace(/(?:\.(?![^.]+$)|[^\w.])+/g, "_");
    return fileNameCleared;
};

function* chunk(s: string, maxBytes: number) {
    const decoder = new TextDecoder("utf-8");
    let buf = new TextEncoder().encode(s);
    while (buf.length) {
        let i = buf.lastIndexOf(32, maxBytes + 1);
        // If no space found, try forward search
        if (i < 0) i = buf.indexOf(32, maxBytes);
        // If there's no space at all, take all
        if (i < 0) i = buf.length;
        // This is a safe cut-off point; never half-way a multi-byte
        yield decoder.decode(buf.slice(0, i));
        buf = buf.slice(i + 1); // Skip space (if any)
    }
}

export const splitStringByByteLength = (d: string, maxBytes: number) => {
    const returnData: string[] = [];
    for (let s of chunk(d, maxBytes)) {
        return returnData.push(s);
    }
    return returnData;
};

export const getFirstLetterNameLastName = (value: string) => {
    if (!value || typeof value !== "string") {
        return null;
    }
    const arrayNames = value.trim().split(" ");
    if (arrayNames.length === 1) {
        return value.charAt(0);
    } else {
        return `${arrayNames[0].charAt(0)}${arrayNames[
            arrayNames.length - 1
        ].charAt(0)}`;
    }
};

export const getFirstNWordsFromText = (textValue: string, nWords: number) => {
    const newTextValue = textValue;
    const arrayWords = newTextValue.split(" ");
    const wordsToReturn =
        nWords <= arrayWords.length ? nWords : arrayWords.length;

    let returnWord = "";
    for (let index = 0; index < wordsToReturn; index++) {
        returnWord = `${returnWord} ${arrayWords[index]}`;
    }
    return returnWord;
};

export const validateEmail = (email: string) => {
    var re = /^[^\s@]+@[^\s@]+$/;
    return re.test(email);
};

export const keepOnlyNumbersInString = (value?: string) => {
    if (value && typeof value === "string") {
        return value.replace(/\D/g, "");
    } else {
        return value;
    }
};
/**
 *
 * Com a atualização da base de dados de países, o código do brasil foi de 30 para 31
 * O código neste useEffect é só um workaround para tratar dados antigos do brasil
 * com código 30
 * @param values Values from source
 * @param fieldName Location field name
 * @returns
 */
export const handleLocationCountryLegacy = (
    values?: Compleo.IObject,
    fieldName = "location"
) => {
    if (values?.[fieldName]?.country?.value === "30") {
        const countryName = values?.[fieldName]?.country?.label || "";
        const isBrazil =
            countryName.toLowerCase().trim().includes("brasil") ||
            countryName.toLowerCase().trim().includes("brazil");
        if (isBrazil) {
            values[fieldName].country.value = "31";
            values[fieldName].country.label = "Brasil";
        }
    }
    const returnData: Compleo.IObject = values || {};
    return returnData;
};

export const replaceAll = (str: string, find: string, replace: string) => {
    return str.replace(new RegExp(find, "g"), replace);
};

export const extractDomainFromURL = (url: string) => {
    const matchedDomains = url.match(
        /\b((?=[a-z0-9-]{1,63}\.)(xn--)?[a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,63}\b/gi
    );

    const result =
        matchedDomains && matchedDomains.length > 0
            ? matchedDomains[0].toUpperCase()
            : url.toUpperCase();

    return result;
};

export const getOpinionOnTheApplicantJob = (t: any) => ({
    value: "opinionOnTheApplicantJob",
    label: t("opinionOnTheApplicantJob")
});

export const getAgeItem = (t: any) => ({
    value: "aged",
    label: String(t("aged"))
});

export const calculateAge = (dateString: string) => {
    try {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return String(age);
    } catch (ex: any) {
        console.log("calculateAge error", ex);
    }
    return "";
};

export const createMarkup = (html?: string) => {
    if (html === undefined) return { __html: "" };
    return { __html: `<pre>${html}</pre>` };
};

/**
 * Extracts the value of the specified parameter from the given pattern string.
 *
 * The function uses a regular expression to search for the parameter followed by
 * a colon, and then captures the value until the next hash (#) or the end of the
 * string.
 *
 * @param pattern - (field from database). The input pattern string to extract the value from.
 * @param parameter - (Example: JOB) The parameter whose value should be extracted.
 * @returns The extracted value as a string, or `null` if the parameter is not found.
 *
 * @example
 * const item1 = "JOBID:5442#GROUP:G1#APPLICANTEMAIL:user@user.com#ASSESSMENTREQUEST:UID";
 * console.log(extractValue(item1, "JOBID")); // Output: "5442"
 * console.log(extractValue(item1, "APPLICANTEMAIL")); // Output: "user@user.com"
 */
export const extractValue = (
    pattern: string | undefined,
    parameter: string
): string | null => {
    if (!pattern) {
        return null;
    }
    const regex = new RegExp(`\\b${parameter}:(.*?)(#|$)`);
    const match = pattern.match(regex);
    if (match && match[1]) {
        return match[1];
    }
    return null;
};

/**
 * Type guard function to check if a value is not null.
 *
 * @template T - The type of the value to check.
 * @param v - The value to check. Can be of type T or null.
 * @returns A boolean indicating whether the value is not null. Also provides a type hint to TypeScript that within a block where `isNotNull(v)` is true, `v` is of type T.
 */
export const isNotNull = <T,>(v: T | null): v is T => {
    return v !== null;
};

/**
 * Strips HTML tags from a given HTML string to extract the text content.
 *
 * This function uses the browser's DOMParser to safely parse HTML and retrieve the text content.
 * This approach avoids the risks associated with regular expressions for HTML parsing, such as
 * missing edge cases or running into performance issues with complex HTML.
 *
 * @remarks
 * This function is intended to be run in a browser environment, as it uses `document.createElement`.
 * For a Node.js environment, alternative libraries like `jsdom` would be more appropriate.
 *
 * @param html - The HTML string to be converted into text.
 * @returns A string containing the text content of the provided HTML.
 */
export function stripHTMLTags(html: string): string {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
}

export function randomNumber() {
    return Math.floor(Math.random() * 1000000);
}
