import React from "react";
// import { FieldProps } from "formik";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { FormHelperText } from "@material-ui/core";
import { getControlOptionsAndChildrenFilterV2 } from "./util/AutoCompleteUtil";
import {
    IInputProps,
    useRhfFieldControlled
} from "../helpers/reactHookFormsHelper";
import { ISelectProps } from "./ComboAutoComplete";
import { useWatch } from "react-hook-form";
import { Box, Divider } from "@material-ui/core";

const RadioButtonGroup = (props: IInputProps & ISelectProps) => {
    const {
        name,
        label,
        options,
        helperTextDefault,
        campoMetadata,
        customLists,
        required,
        isHtml = false,
        ...other
    } = props;
    const language = props.language || "pt-BR";
    const row = other.row === undefined ? false : other.row;

    const {
        error,
        message,
        setFieldValue,
        field,
        getValues,
        setValue,
        control
    } = useRhfFieldControlled(name, helperTextDefault);

    const fieldValue = useWatch({
        control,
        name: name
    });

    const parentFieldValue = useWatch({
        control,
        name: props?.parentFilterField?.parentFieldName
    });

    if (fieldValue === undefined) {
        return null;
    }

    const controlOptionsAndChildrenFilter = getControlOptionsAndChildrenFilterV2(
        campoMetadata,
        options,
        customLists,
        name,
        parentFieldValue,
        props
    );
    const controlOptions = controlOptionsAndChildrenFilter.controlOptions;

    let defaultValue: any = undefined;
    let controlValue = defaultValue;

    if (fieldValue) {
        const valueFromOptions = controlOptions.filter(
            (co: any) => co.value === fieldValue?.value
        )[0];
        controlValue = valueFromOptions;
    }
    return (
        <FormControl component="fieldset" error={error} required={required}>
            <FormLabel component="legend">{label}</FormLabel>
            <RadioGroup
                row={row}
                aria-label={label}
                name={field.name}
                value={controlValue?.value}
                onChange={(event: any, newValue: any | null) => {
                    if (newValue === null) {
                        setFieldValue(defaultValue);
                    } else {
                        const valueToSet = controlOptions.filter(
                            (co: any) => co.value === newValue
                        )[0];
                        setFieldValue(valueToSet);
                    }
                    if (
                        props.childrenFilterFieldName !== undefined &&
                        props.childrenFilterFieldName !== ""
                    ) {
                        if (props.childrenFilterFieldName.includes(",")) {
                            props.childrenFilterFieldName
                                .split(",")
                                .map((item: string) => {
                                    setValue(item, defaultValue);
                                });
                        } else {
                            setValue(
                                props.childrenFilterFieldName,
                                defaultValue
                            );
                        }
                        // setValue(props.childrenFilterFieldName, defaultValue);
                    }
                }}
                onBlur={field.onBlur}
            >
                {controlOptions.map((c: any, index: number) => {
                    const labelOption = c.label || c[`label-${language}`] || "";
                    return (
                        <ElementtItem
                            key={c.value}
                            {...{ c, index, labelOption, field, isHtml }}
                        />
                    );
                })}
                <FormHelperText>{message}</FormHelperText>
            </RadioGroup>
        </FormControl>
    );
};

interface IElelmentItemProps {
    c: any;
    index: number;
    labelOption: string;
    field: any;
    isHtml?: boolean;
}

const ElementtItem = (props: IElelmentItemProps) => {
    const { c, index, labelOption, field, isHtml = false } = props;
    const isOddIndex = index % 2 === 1;

    if (isHtml) {
        return (
            <Box
                p={3}
                style={{
                    backgroundColor: isOddIndex ? "transparent" : "#f2f2f2",
                    marginTop: !isOddIndex ? 10 : 0
                }}
            >
                <FormControlLabel
                    value={c.value}
                    control={<Radio />}
                    label={
                        <div
                            dangerouslySetInnerHTML={{
                                __html: labelOption || ""
                            }}
                        />
                    }
                    aria-label={labelOption}
                    ref={index === 0 ? field.ref : undefined}
                />
            </Box>
        );
    } else {
        return (
            <FormControlLabel
                value={c.value}
                control={<Radio />}
                label={labelOption}
                aria-label={labelOption}
                ref={index === 0 ? field.ref : undefined}
            />
        );
    }
};

export default RadioButtonGroup;
