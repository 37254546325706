import React from "react";
import { FieldProps } from "formik";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import TextField from "@material-ui/core/TextField";
import { FormHelperText, Grid } from "@material-ui/core";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { CurrencyLanguages } from "./CurrencyField";
import {
    IInputProps,
    useRhfFieldControlled
} from "../helpers/reactHookFormsHelper";
interface INumberRange {
    type?: Compleo.IObject;
    minValue?: number | string;
    maxValue?: number | string;
    currency?: string;
}

interface IProps {
    labelMinField: string;
    labelMaxField: string;
    labelTypeTitle: string;
    helperTextDefault: null | string;
    language: CurrencyLanguages;
    decimalDigits?: number;
    options: any;
}

interface ILocalInputProps {
    maxLength?: number;
    startAdornment?: JSX.Element;
    endAdornment?: JSX.Element;
}

const formatMap = {
    "pt-BR": {
        thousandSeparator: ".",
        decimalSeparator: ","
    },
    "en-US": {
        thousandSeparator: ",",
        decimalSeparator: "."
    },
    fr: {
        thousandSeparator: ",",
        decimalSeparator: "."
    },
    es: {
        thousandSeparator: ",",
        decimalSeparator: "."
    }
};

const currencies = [
    {
        value: "BRL",
        label: "R$"
    },
    {
        value: "EUR",
        label: "€"
    },
    {
        value: "USD",
        label: "$"
    }
];

const useStyles = makeStyles((theme: Theme) => ({
    formControlLabel: { fontSize: "0.7rem", "& label": { fontSize: "0.7rem" } },
    formLabel: { fontSize: "0.775rem", "& label": { fontSize: "0.775rem" } },
    root: {
        padding: "3px",
        "& input": {
            height: "24px"
        },
        "& .MuiSvgIcon-fontSizeSmall": {
            fontSize: "0.875rem"
        }
    }
}));

const NumberRangeField = (props: IProps & IInputProps) => {
    const {
        name,
        label,
        labelMinField,
        labelMaxField,
        labelTypeTitle,
        helperTextDefault,
        language,
        maxLength,
        decimalDigits,
        options,
        //inputRef,
        ...other
    } = props;
    const classes = useStyles();

    let inputPropsObj: ILocalInputProps = {};
    if (maxLength) {
        inputPropsObj["maxLength"] = maxLength;
    }
    const {
        error: errorMin,
        message: messageMin,
        field: fieldMin,
        setFieldValue: setFieldMinValue
    } = useRhfFieldControlled(`${name}.minValue`, helperTextDefault);
    const {
        error: errorMax,
        message: messageMax,
        field: fieldMax,
        setFieldValue: setFieldMaxValue
    } = useRhfFieldControlled(`${name}.maxValue`, helperTextDefault);

    const {
        field: fieldTypeValue,
        setFieldValue: setTypeFieldValue,
        message: messageTypeValue,
        error: errorTypeValue
    } = useRhfFieldControlled(`${name}.type.value`, helperTextDefault);
    const {
        field: fieldCurrencyType,
        setFieldValue: setCurrencyTypeFieldValue
    } = useRhfFieldControlled(`${name}.currency`, helperTextDefault);

    if (fieldTypeValue.value === undefined) {
        setTypeFieldValue(options[0].value);
        return null;
    }

    if (fieldTypeValue?.value === "percentage") {
        inputPropsObj["endAdornment"] = (
            <InputAdornment position="end">%</InputAdornment>
        );
    }

    if (fieldTypeValue?.value !== "percentage" && fieldCurrencyType.value) {
        const currencyItem: any = currencies.filter(
            (c) => c.value === fieldCurrencyType.value
        );
        inputPropsObj["startAdornment"] = (
            <InputAdornment position="start">
                {currencyItem[0].label || fieldCurrencyType.value}
            </InputAdornment>
        );
    }

    const handleRadioGroupChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setTypeFieldValue(event.target.value);
    };

    const handleValueMinChange = (prop: string) => (
        values: NumberFormatValues
    ) => {
        setFieldMinValue(values.floatValue);
    };
    const handleValueMaxChange = (prop: string) => (
        values: NumberFormatValues
    ) => {
        setFieldMaxValue(values.floatValue);
    };

    // Destructure the `ref` out so it isn't included in the spread.
    const { ref: minFieldRef, ...minFieldRest } = fieldMin;
    const { ref: maxFieldRef, ...maxFieldRest } = fieldMax;

    return (
        <React.Fragment>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={3}>
                    <NumberFormat
                        label={labelMinField}
                        error={errorMin}
                        helperText={messageMin}
                        onValueChange={handleValueMinChange("minValue")}
                        thousandSeparator={
                            formatMap[language].thousandSeparator
                        }
                        decimalSeparator={formatMap[language].decimalSeparator}
                        isNumericString={true}
                        allowNegative={false}
                        decimalScale={decimalDigits}
                        fixedDecimalScale={true}
                        customInput={TextField}
                        getInputRef={minFieldRef}
                        {...minFieldRest}
                        onChange={undefined}
                        {...other}
                        type="text"
                        {...inputPropsObj}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <NumberFormat
                        label={labelMaxField}
                        error={errorMax}
                        helperText={messageMax}
                        onValueChange={handleValueMaxChange("maxValue")}
                        thousandSeparator={
                            formatMap[language].thousandSeparator
                        }
                        decimalSeparator={formatMap[language].decimalSeparator}
                        isNumericString={true}
                        allowNegative={false}
                        decimalScale={decimalDigits}
                        fixedDecimalScale={true}
                        customInput={TextField}
                        getInputRef={maxFieldRef}
                        {...maxFieldRest}
                        onChange={undefined}
                        {...other}
                        type="text"
                        {...inputPropsObj}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <FormControl component="fieldset" error={errorTypeValue}>
                        <FormLabel component="legend">
                            <Typography className={classes.formLabel}>
                                {labelTypeTitle}
                            </Typography>
                        </FormLabel>
                        <RadioGroup
                            row
                            aria-label={labelTypeTitle}
                            onChange={handleRadioGroupChange}
                            value={fieldTypeValue.value}
                            onBlur={fieldTypeValue.onBlur}
                        >
                            {options.map((c: any, index: number) => {
                                return (
                                    <FormControlLabel
                                        key={c.value}
                                        value={c.value}
                                        control={
                                            <Radio
                                                size="small"
                                                className={classes.root}
                                            />
                                        }
                                        label={
                                            <Typography
                                                className={
                                                    classes.formControlLabel
                                                }
                                            >
                                                {c[`label-${language}`]}
                                            </Typography>
                                        }
                                        labelPlacement="top"
                                        ref={
                                            index === 0
                                                ? fieldTypeValue.ref
                                                : undefined
                                        }
                                    />
                                );
                            })}
                            <FormHelperText>{messageTypeValue}</FormHelperText>
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

NumberRangeField.defaultProps = {
    language: "pt-BR",
    decimalDigits: 2
};

export default NumberRangeField;
