import React from "react";
import Autocomplete, { AutocompleteProps } from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { getControlOptionsAndChildrenFilterV2 } from "../util/AutoCompleteUtil";
import _ from "lodash";
import { getErrorAndMessages } from "../util/general";
import {
    IInputProps,
    useRhfFieldControlled
} from "../../helpers/reactHookFormsHelper";
import { useFormContext, useWatch } from "react-hook-form";
import useJobTitleList from "Pages/JobTitle/components/useJobTitleList";
import { useJobTemplateFieldsToUpdate } from "Pages/JobTitle/components/useJobTemplateFieldsToUpdate";
import { useAuthState } from "_ReactContext/AuthContext";
import { getValuesFromSouceDefaultValue } from "customHooks/useValuesFromSource";

export interface ISelectProps {
    customLists?: Compleo.IObject[];
    language?: string;
    disableClearable?: boolean;
    campoMetadata: Compleo.IObject;
    ignoreCurrentValueIfNotInList?: boolean;
    label?: string;
    completeMetadata?: Compleo.IObject[];
}

const JobComboListText = (props: ISelectProps & IInputProps) => {
    const {
        name,
        label,
        helperTextDefault,
        required,
        language: languageProps,
        ignoreCurrentValueIfNotInList = false,
        completeMetadata,
        ...other
    } = props;
    const {
        error,
        message,
        setFieldValue,
        field,
        getValues,
        watch,
        setValue,
        control
    } = useRhfFieldControlled(name, helperTextDefault);
    const { company, timeZone } = useAuthState();

    const { setValue: setValueRHF } = useFormContext();
    const fieldsToUpdate = useJobTemplateFieldsToUpdate();
    const { listDefinition, fields } = useJobTitleList();
    const [jobData, ready] = listDefinition;
    const [options, setOptions] = React.useState<string[]>([]);
    const optionsFromServer: string[] = (jobData?.data || []).map(
        (item) => item.itemText
    );

    React.useEffect(() => {
        if (optionsFromServer.length) {
            setOptions(sortStringArray(optionsFromServer));
        }
    }, [optionsFromServer.length]);

    const valueFromFormik = watch(field.name);
    const [localValue, setLocalValue] = React.useState<string | null>(null);
    React.useEffect(() => {
        if (options.length && valueFromFormik) {
            const lValue = options.filter(
                (item) =>
                    item.toLowerCase().trim() ===
                    (valueFromFormik || "").toLowerCase().trim()
            );
            if (lValue.length) {
                setLocalValue(lValue[0]);
            } else if (!ignoreCurrentValueIfNotInList) {
                const newOptions = [...options];
                newOptions.push(valueFromFormik);
                setOptions(sortStringArray(newOptions));
            } else {
                setLocalValue(null);
            }
        }
        if (valueFromFormik) {
            onChange(null, valueFromFormik);
        }
    }, [options.length, valueFromFormik]);

    if (valueFromFormik === undefined) {
        return null;
    }

    const onChange = (event: any, newValue: string | null) => {
        setFieldValue(newValue || "");

        const fieldValue = fields.find((item: any) => item.name === newValue);
        const fieldsToDisable: string[] = [];
        fieldsToUpdate.forEach((field) => {
            if (fieldValue?.[field]) {
                setValueRHF(field, fieldValue[field]);
                fieldsToDisable.push(field);
            } else {
                const fieldMetadata = (completeMetadata || []).find(
                    (i: Compleo.IObject) => i.fieldName === field
                );

                if (fieldMetadata) {
                    let valueFromSourceDefault = getValuesFromSouceDefaultValue(
                        fieldMetadata,
                        company
                    );
                    // Handle exceptions
                    if (field === "description") {
                        valueFromSourceDefault = "<p></p>";
                    }

                    setValueRHF(field, valueFromSourceDefault);
                }
            }
        });

        setValueRHF("fieldsToDisable", fieldsToDisable);
    };

    return (
        <Autocomplete
            disableClearable={other.disableClearable}
            className={other.className}
            autoComplete
            multiple={false}
            id={name}
            options={options}
            value={localValue}
            onChange={onChange}
            onBlur={field.onBlur}
            renderInput={(params: any) => (
                <TextField
                    {...(other?.inputProps || {})}
                    {...params}
                    error={error}
                    required={required}
                    label={label}
                    // margin="normal"
                    helperText={message}
                    autoComplete="new-password"
                    data-testid={field.name}
                    // {...field}
                    inputRef={field.ref}
                />
            )}
        />
    );
};

function sortStringArray(arr: string[]): string[] {
    return arr.sort((a, b) => a.localeCompare(b));
}

export default JobComboListText;
