import React from "react";
import EditIcon from "@material-ui/icons/Edit";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { useImportSettings } from "./useImportSettings";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import { MapContentDialog } from "./mapContentDialog";
import { useSyncImportApplicantTemplate } from "./useSyncImportApplicantTemplate";
import { useAutoMapValues } from "./useAutoMapValues";
import { mappingElements } from "../utils";
import { matchSorter } from "match-sorter";
import {
    TextField,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio
} from "@material-ui/core";
import { IImportSettingsSteps } from "./ImportSettingsStep1";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%"
        },
        container: {
            maxHeight: "40vh",
            "&::-webkit-scrollbar": {
                width: "8px",
                display: "none"
            },
            "&:hover::-webkit-scrollbar": {
                display: "block"
            },
            "&::-webkit-scrollbar-track": {
                background: "#f1f1f1",
                borderRadius: "4px"
            },
            "&::-webkit-scrollbar-thumb": {
                background: "#888",
                borderRadius: "4px",
                "&:hover": {
                    background: "#666"
                }
            }
        },
        scrollContainer: {
            height: "45vh",
            overflowY: "auto",
            maxWidth: "36ch",
            "&::-webkit-scrollbar": {
                width: "8px",
                display: "none"
            },
            "&:hover::-webkit-scrollbar": {
                display: "block"
            },
            "&::-webkit-scrollbar-track": {
                background: "#f1f1f1",
                borderRadius: "4px"
            },
            "&::-webkit-scrollbar-thumb": {
                background: "#888",
                borderRadius: "4px",
                "&:hover": {
                    background: "#666"
                }
            }
        },
        inline: {
            display: "inline"
        },
        searchField: {
            margin: theme.spacing(2),
            width: "100%"
        },
        radioGroup: {
            margin: theme.spacing(2)
        },
        filterContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap"
        }
    })
);

export function ImportSettingsStep4(props: IImportSettingsSteps) {
    const { transformSchema, fullData, tBulk } = props;
    const [open, setOpen] = React.useState("");
    const classes = useStyles();
    const columns = transformSchema?.map || [];
    const { ready, dbFields } = useImportSettings();
    useSyncImportApplicantTemplate(props);
    useAutoMapValues(props);

    const [searchQuery, setSearchQuery] = React.useState("");
    const [filterOption, setFilterOption] = React.useState<"all" | "warning">(
        "all"
    );

    if (!ready) {
        return <Loading />;
    }

    const enrichedColumns = columns
        .filter((column) => column.dbColumnName)
        .map((column) => {
            const fieldData = dbFields.find(
                (field) => field.id === column.dbColumnName
            );
            let status = "Pronto";
            let isReady = true;
            let canChange = false;
            if (mappingElements.includes(fieldData?.fullDef?.elementType)) {
                const values = getDistinctValues(fullData, column.columnName);
                const mappedValues = (column.mapValues || []).map(
                    (mappingItem) => mappingItem.fileValue
                );
                const valuesNotMapped = values.filter(
                    (value) => !mappedValues.includes(value)
                );
                if (valuesNotMapped.length > 0) {
                    status = tBulk("mapContent_missingMappingFields", {
                        missingFields: valuesNotMapped.length
                    });
                    isReady = false;
                } else {
                    status = tBulk("mapContent_fieldsMapped", {
                        totalFields: values.length
                    });
                    canChange = true;
                }
            }
            return { column, fieldData, status, isReady, canChange };
        });

    const searchFiltered = searchQuery
        ? matchSorter(enrichedColumns, searchQuery, {
              keys: [
                  (item) => item.column.columnName,
                  (item) => item.fieldData?.label || ""
              ]
          })
        : enrichedColumns;

    const finalColumns =
        filterOption === "warning"
            ? searchFiltered.filter((item) => !item.isReady)
            : searchFiltered;

    return (
        <>
            {open && (
                <MapContentDialog
                    {...props}
                    open={!!open}
                    setOpen={() => setOpen("")}
                    fieldId={open}
                />
            )}
            <Paper className={classes.root}>
                <div className={classes.filterContainer}>
                    <TextField
                        className={classes.searchField}
                        label={tBulk("COMPLEOGENERAL_SEARCH")}
                        variant="outlined"
                        size="small"
                        value={searchQuery}
                        onChange={(event) => setSearchQuery(event.target.value)}
                    />
                    <FormControl
                        component="fieldset"
                        className={classes.radioGroup}
                    >
                        <FormLabel component="legend">
                            {tBulk("mapContent_filter")}
                        </FormLabel>
                        <RadioGroup
                            row
                            value={filterOption}
                            onChange={(event) =>
                                setFilterOption(
                                    event.target.value as "all" | "warning"
                                )
                            }
                        >
                            <FormControlLabel
                                value="all"
                                control={<Radio />}
                                label={tBulk("mapContent_showAll")}
                            />
                            <FormControlLabel
                                value="warning"
                                control={<Radio />}
                                label={tBulk("mapContent_showPendingItems")}
                            />
                        </RadioGroup>
                    </FormControl>
                </div>
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    align="center"
                                    style={{ minWidth: 80 }}
                                >
                                    {tBulk("mapContent_fileColumn")}
                                </TableCell>
                                <TableCell
                                    align="center"
                                    style={{ minWidth: 80 }}
                                >
                                    {tBulk("mapContent_systemField")}
                                </TableCell>
                                <TableCell
                                    align="center"
                                    style={{ minWidth: 80 }}
                                >
                                    {tBulk("mapContent_status")}
                                </TableCell>
                                <TableCell
                                    align="center"
                                    style={{ minWidth: 80 }}
                                >
                                    {tBulk("mapContent_actions")}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {finalColumns.map((item) => {
                                const {
                                    column,
                                    fieldData,
                                    status,
                                    isReady,
                                    canChange
                                } = item;
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={column.id}
                                        style={{
                                            backgroundColor: isReady
                                                ? "white"
                                                : "lightsalmon"
                                        }}
                                    >
                                        <TableCell align="center">
                                            {column.columnName}
                                        </TableCell>
                                        <TableCell align="center">
                                            {fieldData?.label}
                                        </TableCell>
                                        <TableCell align="center">
                                            {status}
                                        </TableCell>
                                        <TableCell align="center">
                                            {(!isReady || canChange) && (
                                                <ButtonIconTooltipCompleo
                                                    label={tBulk(
                                                        "mapContent_actionsEdit"
                                                    )}
                                                    onClick={() => {
                                                        setOpen(column.id);
                                                    }}
                                                >
                                                    <EditIcon fontSize="small" />
                                                </ButtonIconTooltipCompleo>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </>
    );
}

function getDistinctValues(data: Compleo.IObject[], key: string) {
    return Array.from(new Set(data.map((item) => item[key]))).filter(
        (itemValue) => itemValue !== undefined && itemValue !== null
    );
}
