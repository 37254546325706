import React, { useEffect } from "react";
import { IImportSettingsSteps } from "./ImportSettingsStep1";
import TextField from "@material-ui/core/TextField";
import {
    useCompleoSimpleList,
    actionsTableListType,
    actionsTableFunction
} from "customHooks/useCompleoSimpleList";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useAuthState } from "_ReactContext/AuthContext";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import { Box, Button } from "@material-ui/core";
import { NewImportApplicantTemplateDialog } from "./newImportTemplateApplicantDialog";
import { TransformSchema } from "../utils";
import { useHandleUpload } from "./useHandleUpload";

const actionsTable = (t: any, props: IImportSettingsSteps) => {
    const { setTransformSchema, setTemplateDbData, handleNext } = props;
    return (refreshListFunction: any) => {
        const result: actionsTableListType = [
            // {
            //     icon: "delete",
            //     tooltip: t("COMPLEOGENERAL_Delete"),
            //     onClick: (event: any, rowData: any) => {},
            //     hidden: false
            // },
            {
                icon: "done",
                tooltip: t("table_Actions_Select"),
                onClick: (event: any, rowData: any) => {
                    const id = rowData.skGS1pk.split(":")[1];
                    const template: TransformSchema = {
                        id: id,
                        map: [],
                        ...(rowData.template || {})
                    };
                    setTransformSchema(template);
                    setTemplateDbData(rowData);
                    handleNext();
                },
                hidden: false
            }
        ];

        return result;
    };
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        scrollContainer: {
            height: "50vh",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
                width: "8px",
                display: "none"
            },
            "&:hover::-webkit-scrollbar": {
                display: "block"
            },
            "&::-webkit-scrollbar-track": {
                background: "#f1f1f1",
                borderRadius: "4px"
            },
            "&::-webkit-scrollbar-thumb": {
                background: "#888",
                borderRadius: "4px",
                "&:hover": {
                    background: "#666"
                }
            }
        }
    })
);

export function ImportSettingsStep2(props: IImportSettingsSteps) {
    const { tBulk } = props;
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const moduleName = "IMPORTAPPLICANTTEMPLATELIST";
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });
    const [metadata] = useGetMetadata(moduleName);
    const { company } = useAuthState();
    const listAddress = "/exportimportdata/import-applicant-template-list";
    const { handleUpload } = useHandleUpload(props);

    useEffect(() => {
        handleUpload();
    }, []);

    const actions: actionsTableFunction = actionsTable(tBulk, props);
    const [renderTable, finished] = useCompleoSimpleList(
        t,
        metadata,
        listAddress,
        "post",
        {
            companyId: company.companyId
        },
        actions
    );

    let compRetorno: null | JSX.Element = <Loading />;
    if (readyTranslation && finished) {
        compRetorno = renderTable();
        return (
            <Box display={"flex"} flexDirection={"column"}>
                <NewImportApplicantTemplateDialog
                    {...props}
                    open={open}
                    setOpen={setOpen}
                />
                <Box>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setOpen(true)}
                    >
                        {tBulk("COMPLEOGENERAL_NEW")}
                    </Button>
                </Box>
                <Box>
                    <div className={classes.scrollContainer}>{compRetorno}</div>
                </Box>
            </Box>
        );
    } else {
        return <>{compRetorno}</>;
    }
}
