import React from "react";
import useList from "../../..";
import { IListBulk, getT } from "../helperBulkActions";
import useCompleoReactHookForm, {
    useCompleoReactHookFormGetMainData
} from "customHooks/useCompleoReactHookForm";
import FormModal from "customHooks/useCompleoReactHookForm/components/FormModal";
import { useGetMetadataMerge } from "customHooks/useGetMetadata";
import useValuesFromSource from "customHooks/useValuesFromSource";
import { useTranslation } from "react-i18next";
import { apiDirectCall, ApiStateType } from "customHooks/useApi";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import { useAuthState } from "_ReactContext/AuthContext";
import { useQueryClient } from "react-query";
import { FieldsToExport } from "./components/FieldsToExport";

const CustomComponents = [
    {
        name: "fieldsToExport",
        component: FieldsToExport
    }
];

interface IProps {
    formikRef: React.MutableRefObject<any>;
    listBulk: IListBulk[];
    setStepBulk: (value: React.SetStateAction<number>) => void;
    setLoadingButtons: React.Dispatch<React.SetStateAction<boolean>>;
}

export function BulkActionsStep3ApplicantExport(props: IProps) {
    const queryClient = useQueryClient();
    const { company } = useAuthState();

    const { formikRef, listBulk, setStepBulk, setLoadingButtons } = props;
    const [listData] = useList();
    const { t } = listData.tempInfo;
    const module1 = "ELASTICLISTSETTINGSBULKMERGE";
    const module2 = "ELASTICLISTSETTINGSBULKAPPLICANTEXPORT";
    const [metadata] = useGetMetadataMerge(module1, module2);

    const [tLocal, i18n, readyTranslation] = useTranslation(
        [module2, module1],
        {
            useSuspense: false
        }
    );
    const tMerge = getT(tLocal, module1);

    const [initialValues] = useValuesFromSource(metadata, false, {
        fieldsToExport: []
    });
    const ready = readyTranslation && metadata.status === "success";
    const customList: Compleo.ListDefinitionType[] = [];

    const reactHookFormMethods = useCompleoReactHookFormGetMainData({
        t: tMerge,
        ready: ready,
        i18nV: i18n,
        metadadosRetorno: metadata,
        valuesFromSource: initialValues
    });

    const handleSubmit = async (values: any) => {
        setLoadingButtons(true);
        const ids = listBulk
            .filter((item) => item.checked)
            .map((item) => item.value);
        try {
            const data = await apiDirectCall("/bulkactions/baadd", "post", {
                companyId: company.companyId,
                ids: ids,
                fieldsToExport: values?.fieldsToExport || [],
                name: values.name,
                type: "applicant_exportToFile"
            });
        } catch (ex) {
            setLoadingButtons(false);
            return ex.response;
        }
        // listData.tempInfo.useApiKey
        queryClient.invalidateQueries(listData.tempInfo.useApiKey);
        setLoadingButtons(false);
        setStepBulk(99);
        const retorno: Compleo.IObject = {};
        retorno.status = 200;
        return retorno;
    };
    const postReturn: ApiStateType = {
        status: "success",
        response: {},
        exception: null
    };

    const [formCompleo, finished] = useCompleoReactHookForm({
        t: tMerge,
        ready: ready,
        i18nV: i18n,
        postMethod: handleSubmit,
        postReturn: postReturn,
        FormType: FormModal,
        metadadosRetorno: metadata,
        valuesFromSource: initialValues,
        formGroupPaperElevation: 0,
        customLists: customList,
        formRef: formikRef,
        CustomComponents: CustomComponents,
        reactHookFormMethods: reactHookFormMethods
    });

    let FormReturn: JSX.Element = <Loading />;
    if (finished && ready) {
        FormReturn = formCompleo;
    }

    return FormReturn;
}
