import React, { useEffect } from "react";
import Page, { SecondComponentType } from "Pages/_Layouts/DefaultInternal";
import useCompleoReactHookForm, {
    useCompleoReactHookFormGetMainData
} from "customHooks/useCompleoReactHookForm";

import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import FormClassic from "customHooks/useCompleoReactHookForm/components/FormClassic";
import { useAuthState } from "_ReactContext/AuthContext";
import {
    apiDirectCall,
    ApiStateType,
    useApi,
    useApiCache
} from "customHooks/useApi";
import useValuesFromSource from "customHooks/useValuesFromSource";

import { convertParserToCompleoPattern } from "functions/util";
import ApplicantExistsValidation from "../components/ApplicantExistsValidation";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";
import useApplicantNewContext from "./useApplicantNewContext/useApplicantNewContext";

import ApplicantContainerCV from "./ApplicantContainerCV";
import { createLocalUrlFromS3 } from "customHooks/useCompleoReactHookForm/Inputs/File/File";
import { navigate } from "@gatsbyjs/reach-router";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";
import { usePlanLimitsPage } from "customHooks/usePlanLimits/usePlanLimits";
import { useJobStageDropdown } from "customHooks/useJobStageDropdown";
import { useQueryClient } from "react-query";

interface IApplicantVerificationData {}

const ApplicantVerificationData = (props: IApplicantVerificationData) => {
    const [data, dispatchData] = useApplicantNewContext();
    const [readyCV, setReadyCV] = React.useState(false);
    const { operationid, itemid } = data;
    const queryClient = useQueryClient();

    const showMessage = useShowMessage();
    const [isUpdating, setIsUpdating] = React.useState(false);
    const [customList, setCustomList] = React.useState<
        Compleo.ListDefinitionType[]
    >([]);
    const [fieldsToHide, setFieldsToHide] = React.useState<string[]>([
        "cv",
        "autoProcess"
    ]);

    const { readyForm, callDialog, agree } = useGlobalDialog();
    const { company } = useAuthState();
    const moduleName = "APPLICANTVERIFICATIONDATA";
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });
    const language = i18n.languages[0];

    const { limitReached } = usePlanLimitsPage(
        t,
        "/applicant/list",
        "addApplicant"
    );

    const [cvKey, setCvKey] = React.useState<
        { key: string; originalFileName: string } | undefined
    >(undefined);
    const [metadata] = useGetMetadata(moduleName);

    useEffect(() => {
        if (data.localStateData?.jsonParsedCV) {
            const localFieldsToHide = [...fieldsToHide];
            if (localFieldsToHide.includes("autoProcess")) {
                localFieldsToHide.splice(
                    localFieldsToHide.indexOf("autoProcess"),
                    1
                );
                setFieldsToHide(localFieldsToHide);
            }
        }
    }, [data.localStateData?.jsonParsedCV, fieldsToHide]);
    React.useEffect(() => {
        if (cvKey?.key) {
            createLocalUrlFromS3(cvKey.key, true)
                .then((url) => {
                    // Below code is to change the extension of the file to pdf
                    // because the CV is always converted to pdf in function createLocalUrlFromS3
                    const re = /(?:\.([^.]+))?$/;
                    let fileName = cvKey.originalFileName;
                    const extension = (re.exec(fileName) || [])[1];
                    if (extension !== "pdf") {
                        fileName = fileName.replace(/\.[^.]+$/, ".pdf");
                    }

                    const file = new File([url.blob], fileName);
                    if (!readyCV) {
                        setReadyCV(true);
                    }

                    dispatchData({
                        type: "update",
                        payload: {
                            urlCV: url.url,
                            cvFile: {
                                fileName: fileName,
                                streamContent: file
                            }
                        }
                    });
                })
                .catch((err) => {
                    showMessage(t("NotIdentifiedProblem"), "error");
                    if (data.operationid) {
                        navigate(
                            `/applicant/parserlistdetail/${data.operationid}`
                        );
                    }
                });
        }
    }, [cvKey]);
    const postAddressSearch = `/parser/parseroperationsearchitem`;
    const executeJustOnCall = !(
        operationid !== undefined && itemid !== undefined
    );

    let nextStepReturn: ApiStateType = {
        status: "success",
        response: {},
        exception: null
    };
    const nextStepFunction = async (values: any) => {
        let retorno: { [k: string]: any } = {};
        retorno = { status: 200 };
        const jsonData = data?.localStateData?.jsonParsedCV;
        const jobId = values?.job?.value || "";

        if (values?.autoProcess === true) {
            setIsUpdating(true);
            const retorno: Compleo.IObject = {};
            retorno.status = 200;

            const dataPost: Compleo.IObject = {
                companyId: company.companyId,
                jobId: jobId,
                stage: values?.stage,
                disqualifyReason: values?.disqualifyReason,
                ignoreAutomation: values?.ignoreAutomation === true,
                jsonCV: jsonData,
                applicantData: {
                    name: values.name,
                    email: values.email,
                    cpf: values.cpf
                },
                cv: values?.cv
            };

            const postAddress = `/parser/parserautomaticprocessindividual`;

            let applicantId = "";
            try {
                const resultData = await apiDirectCall(
                    postAddress,
                    "post",
                    dataPost
                );
                applicantId = (resultData?.data?.id || "").split(":")[1];
                queryClient.invalidateQueries("/applicant/list");
                if (data.operationid && data.itemid) {
                    const postAddressParserUpdate = `/parser/parseroperationdetaileditstatus`;
                    const dataParserUpdate = await apiDirectCall(
                        postAddressParserUpdate,
                        "post",
                        {
                            operationId: data.operationid,
                            itemId: data.itemid,
                            companyId: values.companyId,
                            status: "finished",
                            applicantId: applicantId
                        }
                    );
                }
            } catch (ex) {
                setIsUpdating(false);
                return ex.response;
            }
            if (data.operationid) {
                navigate(`/applicant/parserlistdetail/${data.operationid}`);
            } else {
                navigate(`/applicant/view/${applicantId}`);
            }
            setIsUpdating(false);
        } else {
            let ret = await ApplicantExistsValidation(values);
            // console.log(ret);
            if (!ret.result) {
                dispatchData({
                    type: "update",
                    payload: {
                        ApplicantFirstData: {
                            name: values.name,
                            email: values.email,
                            cpf: values.cpf,
                            jobId: jobId,
                            disqualifyReason: values?.disqualifyReason,
                            stage: values?.stage,
                            ignoreAutomation: values?.ignoreAutomation
                        },
                        pageToRender: "dataEntry"
                    }
                });
            } else {
                callDialog({
                    title: t("applicantExists_ModalTitle"),
                    bodyText: t("applicantExists_Message"),
                    agreeButtonText: t("applicantExists_Yes"),
                    disagreeButtonText: t("applicantExists_No"),
                    agreeFunction: async () => {
                        dispatchData({
                            type: "update",
                            payload: {
                                ApplicantFirstData: {
                                    name: values.name,
                                    email: values.email,
                                    cpf: values.cpf,
                                    id: ret.applicantData?.pk.split(":")[1],
                                    jobId: jobId,
                                    disqualifyReason: values?.disqualifyReason,
                                    stage: values?.stage,
                                    ignoreAutomation: values?.ignoreAutomation
                                },
                                pageToRender: "edit",
                                editId: ret.applicantData?.pk.split(":")[1]
                            }
                        });
                    },
                    disagreeFunction: () => {}
                });
            }
        }
        return retorno;
    };

    const [getOperation] = useApiCache(
        postAddressSearch,
        "post",
        {
            operationid: operationid,
            itemid: itemid,
            companyId: company.companyId
        },
        executeJustOnCall
    );

    let [valuesFromSource] = useValuesFromSource(metadata, true, {
        disqualifyReason: {
            value: "CP0",
            label: language === "pt-BR" ? "Não informado" : "Not informed"
        }
    });
    if (data.localStateData.cvFile?.streamContent) {
        valuesFromSource.cv = {
            FileInfo: data.localStateData.cvFile?.streamContent,
            tags: []
        };
    }
    let jsonCVFromDB: Compleo.IObject | undefined;

    if (operationid && itemid && getOperation.status === "success") {
        const data =
            (((getOperation.response.data || {}).data || {}).Items || [])[0] ||
            {};

        jsonCVFromDB = data.jsonCV;
        valuesFromSource = convertParserToCompleoPattern(
            valuesFromSource,
            data.jsonCV,
            true
        );

        if (cvKey === undefined) {
            setCvKey(data.cv);
        }
    }
    React.useEffect(() => {
        if (!data.localStateData?.jsonParsedCV && jsonCVFromDB) {
            dispatchData({
                type: "update",
                payload: {
                    jsonParsedCV: jsonCVFromDB
                }
            });
        }
    }, [jsonCVFromDB]);
    const pageReady =
        readyTranslation &&
        (executeJustOnCall || getOperation.status === "success") &&
        readyCV;

    if (data?.localStateData?.jsonParsedCV) {
        valuesFromSource = convertParserToCompleoPattern(
            valuesFromSource,
            data?.localStateData?.jsonParsedCV,
            true
        );
    }
    if (!operationid) {
        if (!readyCV) {
            setReadyCV(true);
        }
    }

    const reactHookFormMethods = useCompleoReactHookFormGetMainData({
        t: t,
        ready: pageReady,
        i18nV: i18n,
        metadadosRetorno: metadata,
        valuesFromSource: valuesFromSource
    });
    const { watch, setValue } = reactHookFormMethods.reactHookFormMethods;
    useJobStageDropdown({
        watch,
        setValue,
        fieldsToHide,
        setFieldsToHide,
        customList,
        setCustomList
    });

    const [formCompleo, finished] = useCompleoReactHookForm({
        t,
        ready: pageReady,
        i18nV: i18n,
        postMethod: nextStepFunction,
        postReturn: nextStepReturn,
        FormType: FormClassic,
        metadadosRetorno: metadata,
        valuesFromSource,
        customLists: customList,
        fieldsToHide: fieldsToHide,
        reactHookFormMethods: reactHookFormMethods
    });

    let compRetorno: boolean | JSX.Element;
    compRetorno = <Loading />;

    if (finished && readyTranslation) {
        compRetorno = formCompleo;
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
                maxWidth={data.localStateData?.urlCV ? "lg" : "md"}
            >
                <ApplicantContainerCV
                    compRetorno={compRetorno}
                    url={data?.localStateData?.urlCV}
                />
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

// const

// UserNew.whyDidYouRender = true;
export default ApplicantVerificationData;
