import { useDropzone } from "react-dropzone";
import { IImportSettingsSteps } from "../ImportSettingsStep1";
import FormLabel from "@material-ui/core/FormLabel";
import Paper from "@material-ui/core/Paper";

export function useHandleDropzone(props: IImportSettingsSteps) {
    const { file, tBulk } = props;
    const dropzoneProps = useDropzone({
        multiple: false,
        maxSize: 5000 * 1000,
        accept: [".csv", ".xlsx"],
        maxFiles: 1
    });
    const { getRootProps, getInputProps } = dropzoneProps;

    const element = (
        <div className="container">
            <div
                {...getRootProps({ className: "dropzone" })}
                style={{ border: "1px dashed", padding: "20px" }}
            >
                <FormLabel component="legend" required={true}>
                    {tBulk("file")}
                </FormLabel>
                <Paper elevation={0}>
                    <input {...getInputProps()} />
                    <p>{tBulk("selectFile")}</p>
                </Paper>
                {file && (
                    <aside>
                        <h4>{tBulk("file")}</h4>
                        <ul>
                            <li key={file.name}>
                                {file.name} - {file.size} bytes
                            </li>
                        </ul>
                    </aside>
                )}
            </div>
        </div>
    );

    return { element, dropzoneProps };
}
