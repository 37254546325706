import {
    useJobListDropdown,
    useStageList
} from "customHooks/useList/ListHelpers/BulkActions/helperBulkActions";
import { useEffect } from "react";
import { UseFormSetValue, UseFormWatch } from "react-hook-form";

interface IUseJobStageDropdown {
    watch: UseFormWatch<Compleo.IObject>;
    setValue: UseFormSetValue<Compleo.IObject>;
    jobFieldName?: string;
    stageFieldName?: string;
    disqualifyFieldName?: string;
    fieldsToHide: string[];
    setFieldsToHide: React.Dispatch<React.SetStateAction<string[]>>;
    customList: Compleo.ListDefinitionType[];
    setCustomList: React.Dispatch<
        React.SetStateAction<Compleo.ListDefinitionType[]>
    >;
}
export function useJobStageDropdown(params: IUseJobStageDropdown) {
    const {
        watch,
        setValue,
        jobFieldName = "job",
        stageFieldName = "stage",
        disqualifyFieldName = "disqualifyReason",
        fieldsToHide,
        setFieldsToHide,
        customList,
        setCustomList
    } = params;
    const jobData = useJobListDropdown();
    const jobDataWatch = watch(jobFieldName);
    const stageDataWatch = watch(stageFieldName);
    useEffect(() => {
        setValue(stageFieldName, null);
    }, [jobDataWatch?.value]);
    useEffect(() => {
        if (
            stageDataWatch?.type !== "rejected" &&
            !fieldsToHide.includes(disqualifyFieldName)
        ) {
            setFieldsToHide([...fieldsToHide, disqualifyFieldName]);
        } else if (
            stageDataWatch?.type === "rejected" &&
            fieldsToHide.includes(disqualifyFieldName)
        ) {
            setFieldsToHide(
                fieldsToHide.filter((item) => item !== disqualifyFieldName)
            );
        }
    }, [stageDataWatch?.value]);
    console.log("jobDataWatch", jobDataWatch);
    const { data: stageData, ready: stageDataReady } = useStageList(
        jobDataWatch?.value,
        jobData.ready
    );
    useEffect(() => {
        const localCustomList = [...customList];
        let listModified = false;
        if (
            stageDataReady &&
            localCustomList.findIndex(
                (item) => item.fieldName === stageFieldName
            ) === -1
        ) {
            localCustomList.push({
                fieldName: stageFieldName,
                data: stageData,
                valueName: "title",
                labelName: "pk"
            });
            listModified = true;
        }
        if (
            jobData.ready &&
            localCustomList.findIndex(
                (item) => item.fieldName === jobFieldName
            ) === -1
        ) {
            localCustomList.push({
                fieldName: jobFieldName,
                data: jobData.data,
                valueName: "title",
                labelName: "pk"
            });
            listModified = true;
        }
        if (listModified) {
            setCustomList(localCustomList);
        }
    }, [stageDataReady, jobData.ready]);
}
