import Page from "Pages/_Layouts/InternalFullPage";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import { useAuthState } from "_ReactContext/AuthContext";
import "openapi-explorer"; // Web component import
//@ts-ignore
import { reactEventListener } from "../../../node_modules/openapi-explorer/dist/es/react.js";
import { useEffect } from "react";
import useQueryString from "customHooks/useQueryString/useQueryString";

const getQueryParamsFromHash = () => {
    const hash = window.location.hash; // "#?route=post-/public/joblist/100047#?route=overview"
    const queryString = hash.split("?")[1]; // "route=post-/public/joblist/100047#?route=overview"
    return new URLSearchParams(queryString);
};

const OpenAPI = (props: any) => {
    const [t, , readyTranslation] = useTranslation("customernew", {
        useSuspense: false
    });
    const endPoint = process.env.REACT_APP_API_ADDRESS;
    const queryString = getQueryParamsFromHash();
    const { company } = useAuthState();
    const companyIdUrl = (
        (queryString.get("route") || "").split("/")[3] || ""
    ).replace("#", "");
    const companyId = company.companyId;
    useEffect(() => {
        if (companyIdUrl && companyId.toString() !== companyIdUrl.toString()) {
            // remove the hash from the url and reload the page
            window.location.hash = "";
            window.location.reload();
        }
    }, [companyId, companyIdUrl]);
    const onRequestFunction = (data: any) => {
        console.log("Request:", data);
    };
    reactEventListener({ useEffect }, "request", onRequestFunction);

    if (readyTranslation) {
        return (
            <Page maxWidth="xl">
                {/* @ts-ignore */}
                <openapi-explorer
                    spec-url={`${endPoint}/public/openapi/${company.companyId}`}
                    server-url={endPoint}
                >
                    {/* @ts-ignore */}
                </openapi-explorer>
            </Page>
        );
    } else {
        return (
            <Page>
                <Loading />
            </Page>
        );
    }
};

export default OpenAPI;
