export type TransformSchema = {
    id: string;
    map: {
        id: string;
        columnName: string;
        dbColumnName?: string;
        ignore?: boolean;
        arrayIndex?: number;
        mapValues?: { fileValue: string; dbValue: string }[];
    }[];
};

export type TransformSchemaFileColumn = TransformSchema["map"][0];

export function getDistinctValues(data: Compleo.IObject[], key: string) {
    return Array.from(new Set(data.map((item) => item[key]))).filter(
        (item) => item !== undefined && item !== null
    );
}

export const mappingElements = ["select", "radio", "radiobuttongroup"];
