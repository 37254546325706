import { useEffect, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { IImportSettingsSteps } from "./ImportSettingsStep1";
import {
    Grid,
    Button,
    List,
    ListItem,
    ListItemText,
    IconButton,
    Radio,
    RadioGroup,
    FormControl,
    FormControlLabel,
    FormLabel,
    TextField,
    Box
} from "@material-ui/core";
import { useImportSettings } from "./useImportSettings";
import { TransformSchemaFileColumn } from "../utils";
import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult
} from "react-beautiful-dnd";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useSyncImportApplicantTemplate } from "./useSyncImportApplicantTemplate";
import { useHandleUpload } from "./useHandleUpload";
import { matchSorter } from "match-sorter";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            marginTop: theme.spacing(1)
        },
        scrollContainer: {
            height: "40vh",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
                width: "8px",
                display: "none"
            },
            "&:hover::-webkit-scrollbar": {
                display: "block"
            },
            "&::-webkit-scrollbar-track": {
                background: "#f1f1f1",
                borderRadius: "4px"
            },
            "&::-webkit-scrollbar-thumb": {
                background: "#888",
                borderRadius: "4px",
                "&:hover": {
                    background: "#666"
                }
            }
        },
        radioGroup: {
            marginBottom: theme.spacing(1)
        },
        searchField: {
            marginBottom: theme.spacing(2)
        },
        buttonGroup: {
            display: "flex",
            alignItems: "center",
            marginTop: theme.spacing(2)
        }
    })
);

export function ImportSettingsStep3(props: IImportSettingsSteps) {
    const classes = useStyles();
    const { setTransformSchema, transformSchema, file, tBulk } = props;
    const { ready, dbFields } = useImportSettings();
    const fileColumns = transformSchema?.map || [];
    useSyncImportApplicantTemplate(props);

    const [mapping, setMapping] = useState<Record<string, string>>({});
    const [searchQueryFileCols, setSearchQueryFileCols] = useState("");
    const [searchQueryDBCols, setSearchQueryDBCols] = useState("");

    // Filter states for each column
    const [fileFilter, setFileFilter] = useState<"all" | "mapped" | "unmapped">(
        "all"
    );
    const [dbFilter, setDbFilter] = useState<"all" | "mapped" | "unmapped">(
        "all"
    );
    const { handleUpload } = useHandleUpload(props);

    useEffect(() => {
        if (fileColumns.length === 0) {
            handleUpload();
        } else {
            const defaultMapping = fileColumns.reduce((acc, col) => {
                acc[col.id] = col.dbColumnName || "";
                return acc;
            }, {} as Record<string, string>);
            setMapping(defaultMapping);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [file]);

    const onDragEnd = (result: DropResult) => {
        const { destination, source, draggableId } = result;
        if (!destination) return;

        // Dragging from dbFields list to a file column (droppableId = file column id)
        if (
            source.droppableId === "dbFields" &&
            destination.droppableId !== "dbFields"
        ) {
            setMapping((prev) => {
                const newValue = {
                    ...prev,
                    [destination.droppableId]: draggableId
                };
                updateTransformSchema(newValue);
                return newValue;
            });
        }
    };

    const updateTransformSchema = (newMapping: Record<string, string>) => {
        if (transformSchema?.id) {
            const newMap = fileColumns.map((col) => {
                const dbColumnName = newMapping[col.id];
                const dbFieldDef = dbFields.find(
                    (field) => field.id === dbColumnName
                );
                if (dbColumnName) {
                    return {
                        ...col,
                        columnName: col.columnName,
                        dbColumnName,
                        arrayIndex: dbFieldDef?.arrayIndex
                    };
                } else {
                    return {
                        ...col,
                        columnName: col.columnName,
                        dbColumnName: ""
                    };
                }
            });
            setTransformSchema({
                ...transformSchema,
                map: newMap
            });
        }
    };

    const removeMapping = (colId: string) => {
        setMapping((prev) => {
            const newMapping = { ...prev };
            delete newMapping[colId];
            updateTransformSchema(newMapping);
            return newMapping;
        });
    };

    const clearAllMappings = () => {
        setMapping({});
        updateTransformSchema({});
    };

    // Auto merge based on field names
    const handleAutoMerge = (
        autoMergeFileColumns: TransformSchemaFileColumn[]
    ) => {
        setMapping((prev) => {
            const newMapping = { ...prev };
            const normalize = (str: string) => {
                return str
                    .normalize("NFD") // Decomposes accents (e.g., "á" -> "á")
                    .replace(/[\u0300-\u036f]/g, "") // Removes combining diacritical marks
                    .replace(/[^a-z0-9 ]/gi, "") // Removes other special characters
                    .toLowerCase()
                    .trim();
            };
            autoMergeFileColumns.forEach((col) => {
                if (!newMapping[col.id]) {
                    const normalizedCol = normalize(col.columnName);
                    const match = dbFields.find((dbField) => {
                        if (Object.values(newMapping).includes(dbField.id))
                            return false;
                        const normalizedDb = normalize(dbField.label);
                        return (
                            normalizedCol === normalizedDb ||
                            normalizedCol.includes(normalizedDb) ||
                            normalizedDb.includes(normalizedCol)
                        );
                    });
                    if (match) {
                        newMapping[col.id] = match.id;
                    }
                }
            });
            updateTransformSchema(newMapping);
            return newMapping;
        });
    };

    const filteredFileColumns = (searchQueryFileCols
        ? matchSorter(fileColumns, searchQueryFileCols, {
              keys: ["columnName"]
          })
        : fileColumns
    ).filter((col) => {
        if (fileFilter === "mapped") return !!mapping[col.id];
        if (fileFilter === "unmapped") return !mapping[col.id];
        return true;
    });

    // Filter DB fields based on dbFilter state and search query
    const filteredDbFields = (searchQueryDBCols
        ? matchSorter(dbFields, searchQueryDBCols, {
              keys: ["label"]
          })
        : dbFields
    ).filter((field) => {
        if (dbFilter === "mapped") {
            return Object.values(mapping).includes(field.id);
        }
        if (dbFilter === "unmapped") {
            return !Object.values(mapping).includes(field.id);
        }
        return true;
    });

    if (!ready) {
        return <Loading />;
    }

    return (
        <Box width={"100%"}>
            {fileColumns.length > 0 && (
                <DragDropContext onDragEnd={onDragEnd}>
                    <Grid container spacing={2}>
                        {/* Left column: File Columns */}
                        <Grid item xs={6}>
                            <TextField
                                className={classes.searchField}
                                label={tBulk("COMPLEOGENERAL_SEARCH")}
                                variant="outlined"
                                size="small"
                                fullWidth
                                value={searchQueryFileCols}
                                onChange={(e) =>
                                    setSearchQueryFileCols(e.target.value)
                                }
                            />
                            <FormControl
                                component="fieldset"
                                className={classes.radioGroup}
                            >
                                <FormLabel component="legend">
                                    {tBulk("mapFields_filterFileColumns")}
                                </FormLabel>
                                <RadioGroup
                                    row
                                    value={fileFilter}
                                    onChange={(e) =>
                                        setFileFilter(
                                            e.target.value as
                                                | "all"
                                                | "mapped"
                                                | "unmapped"
                                        )
                                    }
                                >
                                    <FormControlLabel
                                        value="all"
                                        control={<Radio />}
                                        label={tBulk("mapFields_showAll")}
                                    />
                                    <FormControlLabel
                                        value="mapped"
                                        control={<Radio />}
                                        label={tBulk("mapFields_showMapped")}
                                    />
                                    <FormControlLabel
                                        value="unmapped"
                                        control={<Radio />}
                                        label={tBulk("mapFields_showUnmapped")}
                                    />
                                </RadioGroup>
                            </FormControl>
                            <div className={classes.scrollContainer}>
                                <List>
                                    {filteredFileColumns.map((col) => {
                                        const fieldDef = dbFields.find(
                                            (field) =>
                                                field.id === mapping[col.id]
                                        );

                                        return (
                                            <Droppable
                                                droppableId={col.id}
                                                key={col.columnName}
                                                isDropDisabled={
                                                    !!mapping[col.id]
                                                }
                                            >
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.droppableProps}
                                                    >
                                                        <ListItem
                                                            style={{
                                                                border: snapshot.isDraggingOver
                                                                    ? "2px dashed #999"
                                                                    : "none",
                                                                borderRadius:
                                                                    "4px",
                                                                padding: "4px",
                                                                margin: "4px 0",
                                                                backgroundColor: mapping[
                                                                    col.id
                                                                ]
                                                                    ? "#ffdddd"
                                                                    : "white"
                                                            }}
                                                        >
                                                            {mapping[
                                                                col.id
                                                            ] && (
                                                                <IconButton
                                                                    edge="start"
                                                                    onClick={() =>
                                                                        removeMapping(
                                                                            col.id
                                                                        )
                                                                    }
                                                                >
                                                                    <CloseIcon />
                                                                </IconButton>
                                                            )}
                                                            <ListItemText
                                                                primary={
                                                                    col.columnName
                                                                }
                                                                secondary={
                                                                    mapping[
                                                                        col.id
                                                                    ]
                                                                        ? `${tBulk(
                                                                              "mapFields_mappedTo"
                                                                          )}: ${
                                                                              fieldDef?.label
                                                                          }`
                                                                        : tBulk(
                                                                              "mapFields_dropHere"
                                                                          )
                                                                }
                                                            />
                                                        </ListItem>
                                                        {/* {provided.placeholder} */}
                                                    </div>
                                                )}
                                            </Droppable>
                                        );
                                    })}
                                </List>
                            </div>
                        </Grid>
                        {/* Right column: DB Fields */}
                        <Grid item xs={6}>
                            <TextField
                                className={classes.searchField}
                                label={tBulk("COMPLEOGENERAL_SEARCH")}
                                variant="outlined"
                                size="small"
                                fullWidth
                                value={searchQueryDBCols}
                                onChange={(e) =>
                                    setSearchQueryDBCols(e.target.value)
                                }
                            />
                            <FormControl
                                component="fieldset"
                                className={classes.radioGroup}
                            >
                                <FormLabel component="legend">
                                    {tBulk("mapFields_filterFields")}
                                </FormLabel>
                                <RadioGroup
                                    row
                                    value={dbFilter}
                                    onChange={(e) =>
                                        setDbFilter(
                                            e.target.value as
                                                | "all"
                                                | "mapped"
                                                | "unmapped"
                                        )
                                    }
                                >
                                    <FormControlLabel
                                        value="all"
                                        control={<Radio />}
                                        label={tBulk("mapFields_showAll")}
                                    />
                                    <FormControlLabel
                                        value="mapped"
                                        control={<Radio />}
                                        label={tBulk("mapFields_showMapped")}
                                    />
                                    <FormControlLabel
                                        value="unmapped"
                                        control={<Radio />}
                                        label={tBulk("mapFields_showUnmapped")}
                                    />
                                </RadioGroup>
                            </FormControl>
                            <div className={classes.scrollContainer}>
                                <Droppable droppableId="dbFields">
                                    {(provided) => (
                                        <List
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                        >
                                            {filteredDbFields.map(
                                                (field, index) => {
                                                    const isMapped = Object.values(
                                                        mapping
                                                    ).includes(field.id);
                                                    return (
                                                        <Draggable
                                                            key={field.id}
                                                            draggableId={
                                                                field.id
                                                            }
                                                            index={index}
                                                            isDragDisabled={
                                                                isMapped
                                                            }
                                                        >
                                                            {(provided) => (
                                                                <ListItem
                                                                    ref={
                                                                        provided.innerRef
                                                                    }
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    style={{
                                                                        backgroundColor: isMapped
                                                                            ? "#ffdddd"
                                                                            : "#f0f0f0",
                                                                        ...provided
                                                                            .draggableProps
                                                                            .style
                                                                    }}
                                                                >
                                                                    <ListItemText
                                                                        primary={
                                                                            field.label
                                                                        }
                                                                    />
                                                                </ListItem>
                                                            )}
                                                        </Draggable>
                                                    );
                                                }
                                            )}
                                            {provided.placeholder}
                                        </List>
                                    )}
                                </Droppable>
                            </div>
                        </Grid>
                    </Grid>
                </DragDropContext>
            )}
            <div className={classes.buttonGroup}>
                <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    onClick={() => handleAutoMerge(fileColumns)}
                >
                    {tBulk("mapFields_autoMapping")}
                </Button>
                <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    onClick={clearAllMappings}
                    style={{ marginLeft: 8 }}
                >
                    {tBulk("mapFields_deleteAllMappings")}
                </Button>
            </div>
        </Box>
    );
}
