import useGetMetadata from "customHooks/useGetMetadata";
import useValuesFromSource from "customHooks/useValuesFromSource";

export function useJobTemplateFieldsToUpdate() {
    const [templateMetadata] = useGetMetadata("JOBTITLENEW");
    const [templateFields] = useValuesFromSource(templateMetadata, true, {});
    const fieldsToIgnore = ["name", "companyId"];
    const fieldsToUpdate = Object.keys(templateFields).filter(
        (field) => !fieldsToIgnore.includes(field)
    );
    return fieldsToUpdate;
}
