import { apiDirectCall } from "customHooks/useApi";
import { TransformSchemaFileColumn } from "../../utils";
import { IImportSettingsSteps } from "../ImportSettingsStep1";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";
import { useAuthState } from "_ReactContext/AuthContext";

export function useHandleUpload(props: IImportSettingsSteps) {
    const {
        file,
        setFullData,
        transformSchema,
        setTransformSchema,
        setActiveStep,
        tBulk,
        setFile,
        importApplicantsLimit
    } = props;

    let limitImport = importApplicantsLimit;

    const setFileColumns = (cols: TransformSchemaFileColumn[]) => {
        if (transformSchema?.id) {
            setTransformSchema({
                ...transformSchema,
                map: cols
            });
        }
    };
    const { readyForm, callDialog, agree } = useGlobalDialog();

    const handleUpload = async () => {
        if (!file) {
            return;
        }
        const formData = new FormData();
        formData.append("file", file);
        try {
            const response = await apiDirectCall(
                "/exportimportdata/exceltojson",
                "post",
                formData,
                {
                    headers: { "Content-Type": "multipart/form-data" }
                }
            );
            const result = response.data?.data || {};
            const headers = (result.headers || []) as string[];
            if (headers.length > 0) {
                const cols: TransformSchemaFileColumn[] = headers.map(
                    (header) => ({
                        id: header,
                        columnName: header
                    })
                );
                setFileColumns(cols);
                const fieldsData = result.data || [];
                if (fieldsData.length > limitImport) {
                    callDialog({
                        title: tBulk("COMPLEOGENERAL_ERROR"),
                        bodyText: tBulk("importLimitMessage", {
                            limit: limitImport
                        }),
                        agreeButtonText: tBulk("COMPLEOGENERAL_CLOSE"),
                        agreeFunction: async () => {
                            setActiveStep(0);
                            setFile(null);
                        }
                    });
                } else if (fieldsData.length > 0) {
                    setFullData(fieldsData);
                    return cols;
                } else {
                    callDialog({
                        title: tBulk("COMPLEOGENERAL_ERROR"),
                        bodyText: tBulk("errorEmptyFile"),
                        agreeButtonText: tBulk("COMPLEOGENERAL_CLOSE"),
                        agreeFunction: async () => {
                            setActiveStep(0);
                            setFile(null);
                        }
                    });
                }
            }
            return null;
        } catch (error) {
            console.error("Error uploading file:", error);
        }
    };
    return { handleUpload, setFileColumns };
}
