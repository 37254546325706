import React, { useEffect } from "react";
import clsx from "clsx";
import {
    createStyles,
    makeStyles,
    Theme,
    withStyles,
    WithStyles
} from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import {
    AutoSizer,
    Column,
    Table,
    TableCellRenderer,
    TableHeaderProps
} from "react-virtualized";
import { IImportSettingsSteps } from "./ImportSettingsStep1";
import { useAuthState } from "_ReactContext/AuthContext";
import { apiDirectCall } from "customHooks/useApi";
import _ from "lodash";
import { useImportSettings } from "./useImportSettings";
import { transformDBData } from "functions/util";
import {
    Button,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Popper,
    Box
} from "@material-ui/core";
import { UploadNewFileDialog } from "./uploadNewFileDialog";

declare module "@material-ui/core/styles/withStyles" {
    // Augment the BaseCSSProperties so that we can control jss-rtl
    interface BaseCSSProperties {
        /*
         * Used to control if the rule-set should be affected by rtl transformation
         */
        flip?: boolean;
    }
}

const styles = (theme: Theme) =>
    createStyles({
        flexContainer: {
            display: "flex",
            alignItems: "center",
            boxSizing: "border-box"
        },
        table: {
            // temporary right-to-left patch, waiting for
            // https://github.com/bvaughn/react-virtualized/issues/454
            "& .ReactVirtualized__Table__headerRow": {
                flip: false,
                paddingRight:
                    theme.direction === "rtl" ? "0 !important" : undefined
            }
        },
        tableRow: {
            cursor: "pointer"
        },
        tableRowHover: {
            "&:hover": {
                backgroundColor: theme.palette.grey[200]
            }
        },
        tableCell: {
            flex: 1
        },
        noClick: {
            cursor: "initial"
        }
    });

interface ColumnData {
    dataKey: string;
    label: string;
    numeric?: boolean;
    width: number;
}

interface Row {
    index: number;
}

interface MuiVirtualizedTableProps extends WithStyles<typeof styles> {
    columns: ColumnData[];
    headerHeight?: number;
    onRowClick?: () => void;
    rowCount: number;
    rowGetter: (
        row: Row
    ) => {
        [key: string]: any;
    };
    rowHeight?: number;
}

class MuiVirtualizedTable extends React.PureComponent<
    MuiVirtualizedTableProps
> {
    static defaultProps = {
        headerHeight: 48,
        rowHeight: 48
    };

    getRowClassName = (rowData: any) => {
        const { index, className } = rowData;
        const { classes, onRowClick } = this.props;
        return clsx(
            classes.tableRow,
            classes.flexContainer,
            {
                [classes.tableRowHover]: index !== -1 && onRowClick != null
            },
            className // custom class from rowGetter
        );
    };

    rowRenderer = ({
        index,
        key,
        style,
        isScrolling,
        isVisible
    }: {
        index: number;
        key: string;
        style: React.CSSProperties;
        isScrolling: boolean;
        isVisible?: boolean;
    }) => {
        const { columns, rowHeight, onRowClick } = this.props;
        // Retrieve full row data with custom props (including className)
        const rowData = this.props.rowGetter({ index });
        const combinedClassName = clsx(
            this.getRowClassName({ index }),
            rowData.className
        );

        return (
            <div
                key={key}
                style={style}
                className={combinedClassName}
                role="row"
            >
                {columns.map((column, columnIndex) => {
                    const cellData = rowData[column.dataKey];
                    return (
                        <div
                            key={column.dataKey}
                            role="gridcell"
                            style={{ height: 48, width: column.width }}
                        >
                            {this.cellRenderer({
                                cellData,
                                columnIndex,
                                dataKey: column.dataKey,
                                rowData,
                                rowIndex: index,
                                isScrolling: isScrolling
                            })}
                        </div>
                    );
                })}
            </div>
        );
    };

    cellRenderer: TableCellRenderer = ({ cellData, columnIndex }) => {
        const { columns, classes, rowHeight, onRowClick } = this.props;

        return (
            <TableCell
                component="div"
                className={clsx(classes.tableCell, classes.flexContainer, {
                    [classes.noClick]: onRowClick == null
                })}
                variant="body"
                style={{ height: rowHeight }}
                align={
                    (columnIndex != null && columns[columnIndex].numeric) ||
                    false
                        ? "right"
                        : "left"
                }
            >
                {cellData}
            </TableCell>
        );
    };

    headerRenderer = ({
        label,
        columnIndex
    }: TableHeaderProps & { columnIndex: number }) => {
        const { headerHeight, columns, classes } = this.props;

        return (
            <TableCell
                component="div"
                className={clsx(
                    classes.tableCell,
                    classes.flexContainer,
                    classes.noClick
                )}
                variant="head"
                style={{ height: headerHeight }}
                align={columns[columnIndex].numeric || false ? "right" : "left"}
            >
                <span>{label}</span>
            </TableCell>
        );
    };

    render() {
        const {
            classes,
            columns,
            rowHeight,
            headerHeight,
            ...tableProps
        } = this.props;
        return (
            <AutoSizer>
                {({ height, width }) => (
                    <Table
                        height={height}
                        width={width}
                        rowHeight={rowHeight!}
                        gridStyle={{
                            direction: "inherit"
                        }}
                        headerHeight={headerHeight!}
                        className={classes.table}
                        {...tableProps}
                        rowClassName={this.getRowClassName}
                        rowRenderer={this.rowRenderer}
                    >
                        {columns.map(({ dataKey, ...other }, index) => {
                            return (
                                <Column
                                    key={dataKey}
                                    headerRenderer={(headerProps) =>
                                        this.headerRenderer({
                                            ...headerProps,
                                            columnIndex: index
                                        })
                                    }
                                    className={classes.flexContainer}
                                    cellRenderer={this.cellRenderer}
                                    dataKey={dataKey}
                                    {...other}
                                />
                            );
                        })}
                    </Table>
                )}
            </AutoSizer>
        );
    }
}

const VirtualizedTable = withStyles(styles)(MuiVirtualizedTable);

interface IValidationErrorNested {
    keyword: string;
    dataPath: string;
    schemaPath: string;
    params: {
        [key: string]: any;
    };
    message: string;
}
interface IValidationError {
    keyword: string;
    dataPath: string;
    schemaPath: string;
    params?: {
        errors?: IValidationErrorNested[];
        [key: string]: any;
    };
    message?: string;
}

interface IRow {
    data: { [key: string]: any };
    index: number;
    rowIndex: number;
    valid: boolean;
    errors?: IValidationError[];
}
const useTableStyles = makeStyles((theme: Theme) =>
    createStyles({
        invalidRow: {
            backgroundColor: "rgba(255, 0, 0, 0.1)"
        },
        validRow: {},
        root: {
            width: "100%"
        },
        container: {
            maxHeight: "40vh",
            "&::-webkit-scrollbar": {
                width: "8px",
                display: "none"
            },
            "&:hover::-webkit-scrollbar": {
                display: "block"
            },
            "&::-webkit-scrollbar-track": {
                background: "#f1f1f1",
                borderRadius: "4px"
            },
            "&::-webkit-scrollbar-thumb": {
                background: "#888",
                borderRadius: "4px",
                "&:hover": {
                    background: "#666"
                }
            }
        }
    })
);

export function ImportSettingsStep5(props: IImportSettingsSteps) {
    const {
        transformSchema,
        tBulk,
        file,
        setAllLinesAreValid,
        allLinesAreValid
    } = props;
    const [result, setResult] = React.useState<IRow[]>([]);
    const { company } = useAuthState();
    const classes = useTableStyles();
    const [open, setOpen] = React.useState(false);
    const { ready, dbFields, t } = useImportSettings();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [popoverMessages, setPopoverMessages] = React.useState<string[]>([]);
    // New state for radio filter: show all or errors only
    const [filterOption, setFilterOption] = React.useState<"all" | "error">(
        "all"
    );

    async function handleUpload(): Promise<void> {
        if (!file) return;
        const formData = new FormData();
        formData.append("file", file);
        formData.append("transformSchema", JSON.stringify(transformSchema));
        formData.append("companyId", company.companyId.toString());
        try {
            const response = await apiDirectCall(
                "/exportimportdata/prevalidateimport",
                "post",
                formData,
                {
                    headers: { "Content-Type": "multipart/form-data" }
                }
            );
            const resultData = response.data?.data || [];
            const errorItems = resultData.filter((item: IRow) => !item.valid);
            if (errorItems.length > 0) {
                setAllLinesAreValid(false);
            } else {
                setAllLinesAreValid(true);
            }
            setResult(
                resultData.map((item: IRow, index: number) => ({
                    ...item,
                    index
                }))
            );
        } catch (error) {
            console.error("Error uploading file:", error);
        }
    }

    useEffect(() => {
        handleUpload();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const schemaColumns = transformSchema?.map || [];

    const columnsSet = new Set<string>();
    result.forEach((item) => {
        Object.keys(item.data).forEach((key) => columnsSet.add(key));
    });
    const columnsToIgnore = ["companyId", "deleted"];
    const popperOpen = Boolean(anchorEl);

    // Concatenate error messages from nested params.errors
    function getErrorMessages(
        errors?: IValidationError[],
        arrayIndex?: number
    ): JSX.Element | null {
        if (!errors || errors.length === 0) return null;

        const id = popperOpen ? `popper-errors-${arrayIndex}` : undefined;
        let messages: string[] = [];
        errors.forEach((error) => {
            const isComplexField = error.dataPath.split("/").length > 2;
            const dataPathArray = (error.dataPath || "").split("/");
            const baseFieldName = dataPathArray[1];
            const arrayIndex = Number(dataPathArray[2]) + 1;
            const fieldName = dataPathArray[3];

            if ((error.message || "").trim()) {
                let fieldLabel = "";
                if (isComplexField) {
                    if (fieldName) {
                        fieldLabel =
                            t(baseFieldName) +
                            " - " +
                            t(`${baseFieldName}.${fieldName}`) +
                            " - " +
                            arrayIndex;
                    } else {
                        fieldLabel = t(baseFieldName) + " - " + arrayIndex;
                    }
                }
                const message = (error?.message || "").replace(
                    'should match format "date-time"',
                    tBulk("preCheck_customMessage_invalidDate")
                );
                messages.push(
                    fieldLabel ? `${fieldLabel} - ${message}` : message
                );
            } else if (error.params && Array.isArray(error.params.errors)) {
                error.params.errors.forEach((nested) =>
                    messages.push(nested.message)
                );
            }
            // remove duplicates
            messages = _.uniq(messages);
        });
        const handleClick = (event: React.MouseEvent<HTMLElement>) => {
            setPopoverMessages(messages);
            setAnchorEl(anchorEl ? null : event.currentTarget);
        };
        if (messages.length === 1) {
            return <>{messages[0]}</>;
        }
        return (
            <Box width={"100%"} alignItems={"center"} textAlign={"left"}>
                <Button
                    aria-describedby={id}
                    variant="text"
                    color="secondary"
                    onClick={handleClick}
                    size="small"
                    style={{ textDecoration: "underline", paddingLeft: 16 }}
                >
                    {tBulk("preCheck_clickHereToSeeErrors")}
                </Button>
            </Box>
        );
    }

    const filteredSchemaColumns = schemaColumns.filter(
        (col) =>
            col.dbColumnName &&
            !columnsToIgnore.includes(col.dbColumnName || "")
    );

    // Apply filter to result rows based on radio option
    const filteredRows =
        filterOption === "all" ? result : result.filter((row) => !row.valid);

    const numColumns = 2 + filteredSchemaColumns.length;
    const columns: ColumnData[] = [];
    columns.push({
        dataKey: "line",
        label: tBulk("preCheck_line"),
        numeric: false,
        width: 100
    });
    columns.push({
        dataKey: "status",
        label: tBulk("preCheck_status"),
        numeric: false,
        width: 300
    });
    filteredSchemaColumns.map((col) => {
        let localField = col.dbColumnName;
        let suffix = "";
        let preffix = "";
        const fieldData = dbFields.find(
            (field) => field.id === col.dbColumnName
        );
        const fieldName = fieldData?.fullDef?.fieldName;

        if (fieldData?.fullDef?.fieldName.includes("COMPLEXFIELD:")) {
            const isComplexField = fieldName.includes("cf_");
            localField = fieldName
                .replace("COMPLEXFIELD:", "")
                .replace("_", ".");
            const mainFieldName = fieldName.replace("COMPLEXFIELD:", "");
            const baseFieldName = !isComplexField
                ? mainFieldName.split("_")[0]
                : `cf_${mainFieldName.split("_")[1]}`;
            preffix = t(baseFieldName) + " - ";
            suffix = " - " + (col.arrayIndex || 1);
        }
        columns.push({
            dataKey: col.id,
            label: `${preffix}${t(localField || "") + suffix}`,
            numeric: false,
            width: 300
        });
    });

    const rowGetter = ({ index }: Row) => {
        const row = filteredRows[index] as IRow;
        const resultRow: Compleo.IObject = {};
        filteredSchemaColumns.map((col) => {
            let value: any = undefined;
            const fieldData = dbFields.find(
                (field) => field.id === col.dbColumnName
            );
            const fieldName = fieldData?.fullDef?.fieldName || "";
            const isComplexField = fieldName.includes("cf_");
            if (fieldData?.fullDef?.fieldName.includes("COMPLEXFIELD:")) {
                const mainFieldName = fieldName.replace("COMPLEXFIELD:", "");
                const baseFieldName = !isComplexField
                    ? mainFieldName.split("_")[0]
                    : `cf_${mainFieldName.split("_")[1]}`;
                const childrenFieldName = fieldName
                    .replace("COMPLEXFIELD:", "")
                    .replace(baseFieldName, "")
                    .replace("_", "");
                value = ((row.data || [])[baseFieldName] || [])[
                    (col.arrayIndex || 1) - 1
                ]?.[childrenFieldName];
            } else {
                value = (row.data || {})[col.dbColumnName || ""];
            }
            resultRow[col.id] = transformDBData({
                value: value,
                t,
                language: "pt-BR",
                replaceOption: "-"
            });
        });
        resultRow.line = row.rowIndex + 1;
        resultRow.status = !row.valid
            ? getErrorMessages(row.errors)
            : tBulk("preCheck_lineOk");
        resultRow.className = row.valid ? classes.validRow : classes.invalidRow;
        resultRow.valikd = row.valid;
        const errorsLength = (row.errors || []).length;
        resultRow.rowHeight = errorsLength === 0 ? 48 : 48 + errorsLength * 20;
        return resultRow;
    };

    const totalTableWidth = columns.reduce(
        (total, col) => total + col.width,
        0
    );

    return (
        <>
            <Popper
                open={popperOpen}
                anchorEl={anchorEl}
                placement="bottom-start" // Explicitly set placement
                style={{ zIndex: 1300 }} // Ensure it’s above other elements
            >
                <Paper style={{ padding: 16 }} elevation={3}>
                    <ul>
                        {popoverMessages.map((msg, idx) => (
                            <li key={idx}>{msg}</li>
                        ))}
                    </ul>
                </Paper>
            </Popper>
            <UploadNewFileDialog {...props} open={open} setOpen={setOpen} />
            {!allLinesAreValid && (
                <Button variant="outlined" onClick={() => setOpen(true)}>
                    {tBulk("preCheck_error")}
                </Button>
            )}
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "16px"
                }}
            >
                <FormControl component="fieldset">
                    <FormLabel component="legend">
                        {tBulk("COMPLEOGENERAL_FILTER")}
                    </FormLabel>
                    <RadioGroup
                        row
                        value={filterOption}
                        onChange={(e) =>
                            setFilterOption(e.target.value as "all" | "error")
                        }
                    >
                        <FormControlLabel
                            value="all"
                            control={<Radio />}
                            label={tBulk("preCheck_showAll")}
                        />
                        <FormControlLabel
                            value="error"
                            control={<Radio />}
                            label={tBulk("preCheck_showPendingItems")}
                        />
                    </RadioGroup>
                </FormControl>
            </div>

            <div style={{ overflowX: "auto" }}>
                <Paper
                    style={{
                        height: 400,
                        width: totalTableWidth
                    }}
                >
                    {/* <div style={{ width: totalTableWidth }}> */}
                    <VirtualizedTable
                        rowCount={filteredRows.length}
                        rowGetter={rowGetter}
                        columns={columns}
                    />
                    {/* </div> */}
                    {/* </div> */}
                </Paper>
            </div>
        </>
    );
}
