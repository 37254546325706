import { useEffect } from "react";
import { useAuthState } from "_ReactContext/AuthContext";
import { useApiCache } from "customHooks/useApi";

const useTestTemplate = (uuid?: string) => {
    const { company } = useAuthState();

    const isExistentTest = (uuid || "").startsWith("test_");
    const postTemplateList = `/test/testcompleotemplatelist`;
    const [templateListSearchResult, executeQuery] = useApiCache(
        postTemplateList,
        "post",
        {
            companyId: company.companyId,
            uuid: uuid
        },
        true,
        1000 * 60 * 5
    );

    const searchTest = `/test/search`;
    const [searchTestResult, executeSearchTestQuery] = useApiCache(
        searchTest,
        "post",
        {
            companyId: company.companyId,
            uuid: uuid?.replace("test_", "")
        },
        true,
        1000 * 60 * 5
    );

    useEffect(() => {
        if (uuid) {
            if (isExistentTest) {
                executeSearchTestQuery();
            } else {
                executeQuery();
            }
        }
    }, [uuid, isExistentTest]);

    const templateData:
        | CompleoShared.Assessments.ITestTemplate
        | undefined = isExistentTest
        ? searchTestResult?.response?.data?.data
        : (templateListSearchResult?.response?.data?.data || [])[0];

    type keysTemplate = keyof CompleoShared.Assessments.ITestTemplate;

    const fieldsToDelete: keysTemplate[] = [
        "pk",
        "skGS1pk",
        "deleted",
        "name",
        "companyId",
        "createdAt",
        "lastUpdatedAt",
        "lastUpdatedByUser",
        "createdByUser"
    ];
    if (templateData) {
        if (templateData.name) {
            templateData.modelName = templateData.name;
        }
        fieldsToDelete.forEach((field) => {
            delete templateData[field];
        });
    }

    const ready = !uuid ? true : templateData !== undefined;
    return {
        templateData,
        ready
    };
};

export default useTestTemplate;
