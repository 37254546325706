import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Question from "./Question";
import { Box } from "@material-ui/core";
import { useCorrectTest } from "./useCorrectTest";
import CorrectionButton from "./CorrectionButton";
import { TFunction } from "react-i18next";
import { TestDetail } from "../TestList";

interface IModalViewEditTestProps {
    open: boolean;
    test: CompleoShared.Assessments.IAssessmentApplicant | undefined;
    handleClose: () => void;
    t: TFunction;
}

const ModalViewEditTest = (props: IModalViewEditTestProps) => {
    const { test, handleClose, open, t } = props;
    const {
        correctionMode,
        setCorrectionMode,
        correctionStatus,
        finishCorrection,
        setScore,
        questionsToCorrect,
        questionScore,
        allQuestionsCorrected
    } = useCorrectTest(test);

    const questions: Compleo.IObject[] = test?.questionsApplicant || [];
    if (questions && questions.length > 0) {
        questions?.sort((a, b) => a?.question?.order - b?.question?.order);
    }

    if (test && open) {
        return (
            <div>
                <Dialog
                    open={test !== null}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="lg"
                    fullWidth
                    disableBackdropClick
                >
                    <DialogTitle id="alert-dialog-title">
                        <Box
                            display={"flex"}
                            alignContent={"center"}
                            alignItems={"centers"}
                        >
                            <Box display={"flex"}>
                                <span>{test.testName}</span>
                            </Box>
                            <Box flexGrow={1} />
                            <Box display={"flex"}>
                                <CorrectionButton
                                    correctionMode={correctionMode}
                                    setCorrectionMode={setCorrectionMode}
                                    finishCorrection={finishCorrection}
                                    correctionStatus={correctionStatus}
                                    questionsToCorrect={
                                        questionsToCorrect || []
                                    }
                                    allQuestionsCorrected={
                                        allQuestionsCorrected
                                    }
                                    t={t}
                                    closeModal={handleClose}
                                />
                            </Box>
                        </Box>
                        <Box>
                            <TestDetail t={t} test={test} />
                        </Box>
                    </DialogTitle>

                    <DialogContent dividers>
                        {questions.map((question: any, index) => {
                            question.testType = test.testType;
                            return (
                                <Question
                                    key={index}
                                    question={question}
                                    setScore={setScore}
                                    questionsToCorrect={questionsToCorrect}
                                    questionScore={questionScore}
                                    correctionMode={correctionMode}
                                    index={index}
                                    t={t}
                                />
                            );
                        })}
                    </DialogContent>
                    {!correctionMode && (
                        <DialogActions>
                            <Button
                                onClick={handleClose}
                                autoFocus
                                variant="contained"
                                color="secondary"
                            >
                                {t("COMPLEOGENERAL_CLOSE")}
                            </Button>
                        </DialogActions>
                    )}
                </Dialog>
            </div>
        );
    } else {
        return null;
    }
};

export default ModalViewEditTest;
