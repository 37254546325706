import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import { useApi } from "customHooks/useApi";
import FormModal from "customHooks/useCompleoReactHookForm/components/FormModal";
import useCompleoReactHookForm from "customHooks/useCompleoReactHookForm";
import useGetMetadata from "customHooks/useGetMetadata";
import useValuesFromSource from "customHooks/useValuesFromSource";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

type MapContentProps = {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    fields: string[];
};

export function NewExportTemplateFieldsDialog(props: MapContentProps) {
    const { open, setOpen, fields } = props;
    const handleClose = () => {
        setOpen(false);
    };
    const metadataName = "EXPORTAPPLICANTTEMPLATEFIELDSNEW";
    const [t, i18n, readyTranslation] = useTranslation(metadataName, {
        useSuspense: false
    });

    const postAddress = `/exportimportdata/applicant-export-template-upsert`;

    const [addBranchReturn, addBranch] = useApi(postAddress, "post");
    const [metadata] = useGetMetadata(metadataName);

    const [valuesFromSource] = useValuesFromSource(metadata, true);
    valuesFromSource.deleted = false;
    valuesFromSource.fields = fields;

    const [formCompleo, finished, handleSubmitRHF] = useCompleoReactHookForm({
        FormType: FormModal,
        i18nV: i18n,
        metadadosRetorno: metadata,
        postMethod: addBranch,
        postReturn: addBranchReturn,
        ready: readyTranslation,
        formGroupPaperElevation: 0,
        valuesFromSource: valuesFromSource,
        t: t,
        removeQueryClientItems: [
            "/exportimportdata/applicant-export-template-list"
        ]
    });
    const handleSave = async () => {
        await handleSubmitRHF();
        handleClose();
    };

    let compRetorno: boolean | JSX.Element;
    compRetorno = <Loading />;

    if (finished && readyTranslation) {
        compRetorno = formCompleo;
    }
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">
                {t("a_FeatureMainDescription")}
            </DialogTitle>
            <DialogContent>{compRetorno}</DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    {t("COMPLEOGENERAL_CANCEL")}
                </Button>
                <Button onClick={handleSave} color="primary">
                    {t("COMPLEOGENERAL_SAVE")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
