import { useAuthState } from "_ReactContext/AuthContext";
import { useEffect } from "react";
import { IImportSettingsSteps } from "../ImportSettingsStep1";
import { apiDirectCall } from "customHooks/useApi";
import { TransformSchema } from "../../utils";

interface ISyncImportApplicantTemplateData {
    templateDbData: Compleo.IObject | null;
    transformSchema: TransformSchema | null;
}

export function useSyncImportApplicantTemplate(
    props: ISyncImportApplicantTemplateData,
    enableSync = true
) {
    const { company } = useAuthState();
    const postAddress = `/exportimportdata/import-applicant-template-upsert`;

    // use effect each 30 seconds to update the data

    async function syncImportApplicantTemplateData(
        params: ISyncImportApplicantTemplateData
    ) {
        const { templateDbData, transformSchema } = params;
        if (!templateDbData || !transformSchema) {
            return;
        }
        apiDirectCall(postAddress, "post", {
            companyId: company.companyId,
            uuid: templateDbData.skGS1pk.split(":")[1],
            name: templateDbData.name,
            template: transformSchema
        }).then((response) => {
            console.log("Synced");
        });
    }
    useEffect(() => {
        const interval = setInterval(() => {
            if (props.templateDbData && props.transformSchema && enableSync) {
                syncImportApplicantTemplateData({
                    templateDbData: props.templateDbData,
                    transformSchema: props.transformSchema
                });
            }
        }, 30000);
        return () => clearInterval(interval);
    }, [props.templateDbData, props.transformSchema]);

    return { syncImportApplicantTemplateData };
}
