import React, { useEffect } from "react";
import useList from "../../..";
import {
    IListBulk,
    getT,
    useJobList,
    useStageList
} from "../helperBulkActions";
import useCompleoReactHookForm, {
    useCompleoReactHookFormGetMainData
} from "customHooks/useCompleoReactHookForm";
import { useGetMetadataMerge } from "customHooks/useGetMetadata";
import useValuesFromSource from "customHooks/useValuesFromSource";
import { useTranslation } from "react-i18next";
import { ApiStateType } from "customHooks/useApi";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import { useQueryClient } from "react-query";
import FormModal from "customHooks/useCompleoReactHookForm/components/FormModal";
import { ImportSettings } from "./components/ImportSettings";

const CustomComponents = [
    {
        name: "importSettings",
        component: ImportSettings
    }
];

interface IProps {
    formikRef: React.MutableRefObject<any>;
    listBulk: IListBulk[];
    setStepBulk: (value: React.SetStateAction<number>) => void;
    otherGenericParamsListQuery?: Compleo.IObject;
    setLoadingButtons: React.Dispatch<React.SetStateAction<boolean>>;
    nextStep: () => void;
    previousStep: () => void;
    onClose: (value?: string) => void;
}

export function BulkActionsStep3ImportApplicants(props: IProps) {
    const queryClient = useQueryClient();
    const {
        formikRef,
        setStepBulk,
        setLoadingButtons,
        nextStep,
        previousStep,
        onClose
    } = props;
    const [listData] = useList();
    const { t } = listData.tempInfo;
    const module1 = "ELASTICLISTSETTINGSBULKMERGE";
    const module2 = "ELASTICLISTSETTINGSIMPORTAPPLICANTFROMFILE";
    const jobData = useJobList();
    const [fieldsToHide, setFieldsToHide] = React.useState<string[]>([
        "disqualifyReason"
    ]);

    const [metadata] = useGetMetadataMerge(module1, module2);

    const [tLocal, i18n, readyTranslation] = useTranslation(
        [module2, module1],
        {
            useSuspense: false
        }
    );
    const language = i18n.languages[0];

    const tMerge = getT(tLocal, module1);

    const [initialValues] = useValuesFromSource(metadata, true, {
        disqualifyReason: {
            value: "CP0",
            label: language === "pt-BR" ? "Não informado" : "Not informed"
        }
    });
    const customList: Compleo.ListDefinitionType[] = [];
    if (jobData.ready) {
        customList.push({
            fieldName: "job",
            data: jobData.data,
            valueName: "title",
            labelName: "pk"
        });
    }

    const ready = readyTranslation && metadata.status === "success";

    const handleSubmit = async (values: any) => {
        setLoadingButtons(true);
        queryClient.invalidateQueries(listData.tempInfo.useApiKey);
        setLoadingButtons(false);
        setStepBulk(99);
        const retorno: Compleo.IObject = {};
        retorno.status = 200;
        return retorno;
    };
    const addProps: Compleo.useCompleoForm.AdditionalPropertiesField[] = [
        {
            fieldName: "importSettings",
            props: {
                nextStep: nextStep,
                previousStep: previousStep,
                onClose: onClose,
                handleSubmit: handleSubmit,
                tBulk: tMerge
            }
        }
    ];

    const postReturn: ApiStateType = {
        status: "success",
        response: {},
        exception: null
    };
    const reactHookFormMethods = useCompleoReactHookFormGetMainData({
        t: tMerge,
        ready: ready,
        i18nV: i18n,
        metadadosRetorno: metadata,
        valuesFromSource: initialValues
    });
    const { watch, setValue } = reactHookFormMethods.reactHookFormMethods;
    const jobDataWatch = watch("job");
    const stageDataWatch = watch("stage");
    useEffect(() => {
        setValue("stage", null);
    }, [jobDataWatch?.value]);
    useEffect(() => {
        if (
            stageDataWatch?.type !== "rejected" &&
            !fieldsToHide.includes("disqualifyReason")
        ) {
            setFieldsToHide([...fieldsToHide, "disqualifyReason"]);
        } else if (
            stageDataWatch?.type === "rejected" &&
            fieldsToHide.includes("disqualifyReason")
        ) {
            setFieldsToHide(
                fieldsToHide.filter((item) => item !== "disqualifyReason")
            );
        }
    }, [stageDataWatch?.value]);
    const { data: stageData, ready: stageDataReady } = useStageList(
        jobDataWatch?.value,
        true
    );
    if (stageDataReady) {
        customList.push({
            fieldName: "stage",
            data: stageData,
            valueName: "title",
            labelName: "pk"
        });
    }

    const [formCompleo, finished, handleSubmitLocal] = useCompleoReactHookForm({
        t: tMerge,
        ready: ready,
        i18nV: i18n,
        postMethod: handleSubmit,
        postReturn: postReturn,
        FormType: FormModal,
        metadadosRetorno: metadata,
        valuesFromSource: initialValues,
        formGroupPaperElevation: 0,
        formRef: formikRef,
        reactHookFormMethods: reactHookFormMethods,
        CustomComponents: CustomComponents,
        customLists: customList,
        additionalFieldProperties: addProps,
        fieldsToHide: fieldsToHide
    });

    let FormReturn: JSX.Element = <Loading />;

    if (ready && finished) {
        FormReturn = formCompleo;
    }

    return <>{FormReturn} </>;
}
