import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { TransitionProps } from "@material-ui/core/transitions";
import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult
} from "react-beautiful-dnd";
import { IImportSettingsSteps } from "../ImportSettingsStep1";
import { useImportSettings } from "../useImportSettings";
import { getDistinctValues, TransformSchemaFileColumn } from "../../utils";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

type MapContentProps = IImportSettingsSteps & {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    fieldId: string;
};

export function MapContentDialog(props: MapContentProps) {
    const {
        open,
        setOpen,
        fieldId,
        transformSchema,
        fullData,
        tBulk,
        language
    } = props;
    const fieldDef: TransformSchemaFileColumn | undefined = (
        transformSchema?.map || []
    ).find((i) => i.id === fieldId);
    const { ready, getListFromField } = useImportSettings();
    const listMetadata = getListFromField(fieldDef?.dbColumnName).map(
        (i: Compleo.IObject) => i["label-" + language]
    );
    const distinctFileValues = getDistinctValues(fullData, fieldId);

    const defaultMapping = (fieldDef?.mapValues || []).reduce(
        (acc, i) => ({
            ...acc,
            [i.fileValue]: i.dbValue
        }),
        {}
    );
    const [mapping, setMapping] = useState<Record<string, string>>(
        defaultMapping
    );

    const onDragEnd = (result: DropResult) => {
        const { destination, source, draggableId } = result;
        if (!destination) return;

        // Only allow drops from metadata list to a distinct file value droppable
        if (
            source.droppableId === "metadata" &&
            distinctFileValues.includes(destination.droppableId)
        ) {
            setMapping((prev) => ({
                ...prev,
                [destination.droppableId]: draggableId
            }));
        }
    };

    const removeMapping = (fileVal: string) => {
        setMapping((prev) => {
            const newMapping = { ...prev };
            delete newMapping[fileVal];
            return newMapping;
        });
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = () => {
        if (fieldDef && transformSchema?.id) {
            const newMapValues = Object.entries(mapping).map(
                ([fileValue, dbValue]) => ({
                    fileValue,
                    dbValue
                })
            );
            const newMap: TransformSchemaFileColumn = {
                ...fieldDef,
                mapValues: newMapValues
            };
            const newMapList: TransformSchemaFileColumn[] = (
                transformSchema?.map || []
            ).map((i: TransformSchemaFileColumn) =>
                i.id === fieldId ? newMap : i
            );
            props.setTransformSchema({
                ...transformSchema,
                map: newMapList
            });
            setOpen(false);
        }
    };

    if (!ready) {
        return <Loading />;
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">
                {tBulk("mapContent_modalTitle")}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {tBulk("mapContent_modalInstructions")}
                </DialogContentText>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Grid container spacing={2}>
                        {/* Left Column: Distinct File Values */}
                        <Grid item xs={6}>
                            {distinctFileValues
                                .filter((value) => value)
                                .map((value) => (
                                    <Droppable
                                        droppableId={value}
                                        key={value}
                                        isDropDisabled={!!mapping[value]}
                                    >
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}
                                                style={{
                                                    border: snapshot.isDraggingOver
                                                        ? "2px dashed #999"
                                                        : "1px solid #ccc",
                                                    borderRadius: 4,
                                                    padding: 8,
                                                    marginBottom: 8,
                                                    minHeight: 40
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        marginBottom: 4,
                                                        fontWeight: "bold"
                                                    }}
                                                >
                                                    {value}
                                                </div>
                                                {mapping[value] ? (
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            backgroundColor:
                                                                "#ffdddd",
                                                            padding: 4
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                flexGrow: 1
                                                            }}
                                                        >
                                                            {mapping[value]}
                                                        </span>
                                                        <IconButton
                                                            onClick={() =>
                                                                removeMapping(
                                                                    value
                                                                )
                                                            }
                                                            size="small"
                                                        >
                                                            <CloseIcon fontSize="small" />
                                                        </IconButton>
                                                    </div>
                                                ) : (
                                                    <div
                                                        style={{
                                                            color: "#777"
                                                        }}
                                                    >
                                                        {tBulk(
                                                            "mapContent_modalDropHere"
                                                        )}
                                                    </div>
                                                )}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                ))}
                        </Grid>
                        {/* Right Column: Metadata List */}
                        <Grid item xs={6}>
                            <Droppable droppableId="metadata">
                                {(provided) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        style={{
                                            minHeight: 200,
                                            border: "1px solid #ccc",
                                            borderRadius: 4,
                                            padding: 8
                                        }}
                                    >
                                        {listMetadata.map(
                                            (meta: string, index: number) => {
                                                const isMapped = Object.values(
                                                    mapping
                                                ).includes(meta);
                                                return (
                                                    <Draggable
                                                        key={meta}
                                                        draggableId={meta}
                                                        index={index}
                                                        isDragDisabled={
                                                            isMapped
                                                        }
                                                    >
                                                        {(provided) => (
                                                            <div
                                                                ref={
                                                                    provided.innerRef
                                                                }
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={{
                                                                    marginBottom: 8,
                                                                    padding: 4,
                                                                    backgroundColor: isMapped
                                                                        ? "#ffdddd"
                                                                        : "#f0f0f0",
                                                                    ...provided
                                                                        .draggableProps
                                                                        .style
                                                                }}
                                                            >
                                                                {meta}
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            }
                                        )}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </Grid>
                    </Grid>
                </DragDropContext>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {tBulk("COMPLEOGENERAL_CANCEL")}
                </Button>
                <Button onClick={handleSave} color="primary">
                    {tBulk("COMPLEOGENERAL_SAVE")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
